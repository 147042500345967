import {Input, InputWrapperDiv} from "../../../../../../components/ui/Input";
import React, {useEffect, useState} from "react";
import {validate} from "../../../../../sign_up/components/validations";
import Select from "react-select";
import {Colors} from "../../../../../../components/theme";
import DatePicker from "react-datepicker";
import {fr} from "date-fns/locale";
import {
    countryList,
    genderList,
    languagesList,
    musician_status,
    phoneCountryList,
    fieldMap
} from "../../../../../../helpers/dictionary/users";
import {draftProfileUpdateAPI} from "../../../../../../services/api/user";

const select_styles = {
    container: state => ({
        position: "relative",
        margin: "5px 0px",
        backgroundColor: "white",
        padding: "2.5px 0px",
        border: state.isFocused
            ? "1px solid " + Colors.darkRed
            : "1px solid " + Colors.darkGray,
        "&:hover": {
            borderColor: Colors.darkRed,
        },
        "div[id$='listbox']": {
            position: "relative",
        },
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: "4px 10px",
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: Colors.darkGray,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: state.isDisabled ? "5px 5px" : "5px 15px",
        "&:hover": {
            borderLeft: state.isDisabled ? "" : "10px solid" + Colors.darkRed,
            paddingLeft: state.isDisabled ? "5px" : "5px",
            backgroundColor: state.isDisabled ? "" : Colors.lightBG,
            cursor: state.isDisabled ? "" : "pointer",
        },
        color: state.isDisabled ? Colors.lightGray : Colors.darkGray,
        borderTop: "1px solid" + Colors.lightGray,
    }),
    multiValue: baseStyles => ({
        ...baseStyles,
        backgroundColor: Colors.red,
        color: Colors.white,
        margin: "5px 10px 5px 0px",
        "&:hover": {
            //backgroundColor: Colors.darkRed,
            cursor: "pointer",
        },
    }),
    multiValueLabel: baseStyles => ({
        padding: "2px 5px",
    }),
    multiValueRemove: baseStyles => ({
        ...baseStyles,
        padding: "2px 5px",
        backgroundColor: Colors.darkRed,
        "&:hover": {
            backgroundColor: Colors.darkGray,
        },
    }),
};

const PersonalDataForm = ({form, setForm}) => {
    const [formPD, setFormPD] = useState(form);
    const [field, setField] = useState({fieldName: "", value: ""});

    useEffect(() => {
        setFormPD(form);
    }, [form]);

    useEffect(() => {
        const updateDraftData = async () => {
            try {
                const field_name_change = () => {
                    const field_map_backend = fieldMap[field.fieldName];

                    if (field_map_backend) {
                        if (field_map_backend === 'birth_date') {
                            return {[field_map_backend]: `${field.value.getFullYear()}-${field.value.getMonth()+1}-${field.value.getDate()}`}
                        };

                        if (field_map_backend === 'phone') {
                            let phone;
                            if (field.fieldName === 'phoneCountry') {
                                phone = `${field.value.value}-${formPD.phoneNumber.value}`
                            } else {
                                phone = `${formPD.phoneCountry.value.value}-${field.value}`;
                            };
                            return {[field_map_backend]: phone}
                        };

                        switch (field.fieldName) {
                            case "street":
                            case "houseNumber":
                            case "postCode":
                            case "city":
                            case "country":
                            case "mainLang":
                            case "additionalLang":
                                return field_map_backend(field.value);
                                break
                            default:
                                return {[field_map_backend]: field.value}
                                break
                        }

                        // return {[field_map_backend]: field.value}

                    } else {
                        if (field.fieldName === 'gender') {
                            return {'gender': field.value.value};
                        }
                    };
                    return {[field.fieldName]: field.value};

                };

                const draftResponse = await draftProfileUpdateAPI(field_name_change());

            } catch(ex) {
                console.error("Nie udalo sie zmodyfikowac draftu...");
                return
            };

        }

        updateDraftData();

    }, [field]);

    const changeHandler = (value, fieldName) => {
        const error = validate(formPD[fieldName].rules, value);

        const js_data = new Object();
        js_data[fieldName] = value;

        const newEntry = {
            ...formPD,
            [fieldName]: {
                ...formPD[fieldName],
                value: Array.isArray(value) ? value.map(x => x.value) : value,
                error
            },
        };
        setField({"fieldName": fieldName, "value": value});
        setFormPD(newEntry);
        // setForm(newEntry);
    };

    return (<>
        <div className='row pb-2'>
            <InputWrapperDiv>
                <Input
                    placeholder='First name *'
                    name='first_name'
                    value={formPD.firstName.value}
                    onChange={val => changeHandler(val, "firstName")}
                    readOnly={true}
                />
            </InputWrapperDiv>
        </div>
        <div className='row pb-2'>
            <InputWrapperDiv>
                <Input
                    placeholder='Last name *'
                    name='last_name'
                    value={formPD.lastName.value}
                    onChange={val => changeHandler(val, "lastName")}
                />
            </InputWrapperDiv>
        </div>

        <div className='row pb-2'>
            <InputWrapperDiv>
                <DatePicker
                    name='birthDate'
                    placeholderText='Birth date *'
                    selected={formPD.birthDate.value}
                    onChange={val => changeHandler(val, "birthDate")}
                    dateFormat='dd-MM-yyyy'
                    maxDate={new Date()}
                    // onChangeRaw={(event) => handleChangeRaw(event.target.value)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    locale={fr}
                    wrapperClassName='dataInputWrapper'
                    className='dataInput'
                />
            </InputWrapperDiv>
        </div>

        <div className='row pb-2'>
            <div className={`col`}>
                <Select
                    name='gender'
                    placeholder='Gender *'
                    options={genderList}
                    value={formPD.gender.value}
                    defaultValue={{ value: "placeholder", label: "Choose *" }}
                    onChange={val => changeHandler(val, "gender")}
                    styles={select_styles}
                    unstyled
                />
            </div>
        </div>

        <div className='row pb-2'>
            <InputWrapperDiv addClass='col-5'>
                <div className='customSelectParent'>
                    <Select
                        name='phone_country'
                        options={phoneCountryList}
                        value={formPD.phoneCountry.value}
                        onChange={val => changeHandler(val, "phoneCountry")}
                        styles={select_styles}
                        unstyled
                    />
                </div>
            </InputWrapperDiv>
            <InputWrapperDiv addClass='col-7'>
                <Input
                    placeholder='Phone number *'
                    name='phone_number'
                    value={formPD.phoneNumber.value}
                    onChange={val => changeHandler(val, "phoneNumber")}
                />
            </InputWrapperDiv>
        </div>

        <div className='row pb-2'>
            <InputWrapperDiv>
                <Input
                    placeholder='Email address *'
                    name='email_addess'
                    type='email'
                    value={formPD.email.value}
                    onChange={val => changeHandler(val, "email")}
                />
            </InputWrapperDiv>
        </div>

        <div className='row pb-2'>
            <InputWrapperDiv>
                <Input
                    placeholder='Street *'
                    name='street'
                    value={formPD.street.value}
                    onChange={val => changeHandler(val, "street")}
                />
            </InputWrapperDiv>
            <InputWrapperDiv>
                <Input
                    placeholder='House / Appartment No. *'
                    name='house_number'
                    value={formPD.houseNumber.value}
                    onChange={val => changeHandler(val, "houseNumber")}
                />
            </InputWrapperDiv>
        </div>
        <div className='row pb-2'>
            <InputWrapperDiv>
                <Input
                    placeholder='Postcode *'
                    name='postcode'
                    value={formPD.postCode.value}
                    onChange={val => changeHandler(val, "postCode")}
                />
            </InputWrapperDiv>
            <InputWrapperDiv>
                <Input
                    placeholder='City *'
                    name='city'
                    value={formPD.city.value}
                    onChange={val => changeHandler(val, "city")}
                />
            </InputWrapperDiv>
        </div>
        <div className='row pb-2'>
            <div className={`col  `}>
                <div className='customSelectParent'>
                    <Select
                        name='country'
                        placeholder='Country *'
                        options={countryList}
                        value={formPD.country.value}
                        onChange={val => changeHandler(val, "country")}
                        styles={select_styles}
                        unstyled
                    />
                </div>
            </div>
        </div>

        <div className='row pb-4'>
				<div className={`col `}>
					<p className='mt-0 mb-1'>Intermittent du spectacle *</p>
					<Input
						type='radio'
						name='musicianStatus'
                        options={musician_status}
						value={formPD.musicianStatus.value}
						onChange={val => changeHandler(val, "musicianStatus")}
					/>
				</div>
			</div>

        <div className='row pb-2'>
            <div className={`col  `}>
                <div className='langSelectParent'>
                    <Select
                        // name='main_language'
                        placeholder='Main communication language *'
                        options={languagesList}
                        value={ formPD.mainLang.value }
                        onChange={val => changeHandler(val, "mainLang")}
                        styles={select_styles}
                        unstyled
                    />
                </div>
            </div>
        </div>

        <div className='row pb-2'>
            <div className={`col  `}>
                <div className='langSelectParent'>
                    <Select
                        placeholder='Additional languages *'
                        closeMenuOnSelect={false}
                        isMulti
                        options={languagesList}
                        value={languagesList.filter(obj =>
                            formPD.additionalLang.value.includes(obj.value)
                        )}
                        onChange={val => changeHandler(val, "additionalLang")}
                        styles={select_styles}
                        unstyled
                    />
                </div>
            </div>
        </div>


    </>)
};

export default PersonalDataForm;

