import {Page, Document} from "@react-pdf/renderer";
import React, {useEffect, useState} from "react";
import { styles } from "./Styles/Styles";
import Recipient from "./Recipient";
import HeaderPDF from "./HeaderPDF";
import Intro from "./Intro";
import Events from "./Events";
import Staff from "./Staff";
import Total from "./Total";
import Footer from "./Footer";
import {getBusinessData} from "../../../services/functionsAPI";
import {getMamagementData} from "../../../services/functionsAPI";
import OtherDataPDF from "./OtherDataPDF";
import {pdf_calculation_estimate} from "../../../pages/roles/admin/PageContent/Sales/helpers/calculationTotal";
import {isObjectEmpty} from "../../tools";


const PDFFile = ({doc}) => {
	const [business, setBusines] = useState({})
	const [management, setManagement] = useState(null)
	const [eventsCosts, setEventsCosts] = useState({})

	useEffect(() => {
		const get_managementDataAPI = async () => {
			const response = await getMamagementData()
			if (response) {
				setManagement(response)
			} else {console.log("Failed to download management data.")}
		}

		const get_businessDataAPI = async () => {
			const response = await getBusinessData()
			setBusines(response)
		}
		get_managementDataAPI()
		get_businessDataAPI()

		const costs = doc.calculation.eventDetail.length > 0 ? pdf_calculation_estimate(doc.calculation, doc.showEstroGrant) : 0

		if (!isObjectEmpty(costs)) {
			const musiciansSum = Object.keys(costs).reduce((acc, key) => {
				return acc += costs[key]
			}, 0)
			setEventsCosts({...costs, musiciansSum: musiciansSum})
		}
	}, [])

	console.info("Data doc >>>>>", doc)

	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<HeaderPDF
					business={business}
				/>
				<Recipient
					client={doc.client}
					firm={doc.firm}
					docDate={doc.estimateDate}
				/>
				<Intro
					project={doc.project}
					estimateID={doc.estimateID}
				/>
				<Events
					eventsList={doc.calculation.eventDetail}
					eventsCosts={eventsCosts}
					events={doc.calculation.events}
				/>
				<Staff
					eventStaff={doc.calculation.staff}
				/>
				<Total
					projProgram={doc.projectProgram}
					projectData={doc.calculation}
					musiciansSum={eventsCosts.musiciansSum}
				/>
				<OtherDataPDF
					note={doc.comment}
					showGrant={doc.showEstroGrant}
					totaling={doc.calculation.totaling}
				/>
				<Footer business={business} management={management} />
			</Page>
		</Document>
	);

};

export { PDFFile };
