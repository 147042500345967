import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import {fr} from "date-fns/locale";
import Select from "react-select";
import {languagesList} from "../../../../../../helpers/dictionary/users";
import select_styles from "../../../../../../layouts/elements";
import SlideButton from "../../../../../../components/ui/SlideButton";
import React from "react";
import DatePicker from "react-datepicker";
import {useTranslation} from "react-i18next";

function ParametersPdfForm({formData, dispatch}) {
    const { t } = useTranslation()

    return (<>
        <div className="row">
            <div className="col-lg-6">
                <InputWrapperDiv>
                    {t("offer.create_offer.document_date")}
                    <DatePicker
                        name='event_date'
                        selected={formData.date}
                        onChange={val => dispatch({
                            type: "SET_DATE",
                            value: val,
                        })}
                        dateFormat='dd-MM-yyyy'
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode='select'
                        locale={fr}
                        wrapperClassName='dataInputWrapper'
                        className='dataInput'
                    />
                </InputWrapperDiv>
            </div>

            <div className="col-lg-6">
                <InputWrapperDiv>
                    {t("offer.create_offer.select_language")}
                    <Select
                        name='firms-list'
                        options={languagesList}
                        value={formData.language}
                        onChange={val => dispatch({
                            type: "SET_LANGUAGE",
                            value: val,
                        })}
                        styles={select_styles}
                        unstyled
                        required="required"
                    />
                </InputWrapperDiv>
            </div>

            <div className="col-lg-6 mt-3">
                <InputWrapperDiv>
                    {t("offer.create_offer.project_program_title")}
                    <SlideButton
                        type='yesNo'
                        val={formData.showEstroGrant}
                        setVal={val => dispatch({
                            type: "SET_SHOW_ESTRO_GRANT",
                            value: val,
                        })}
                    />
                </InputWrapperDiv>
            </div>
        </div>

        <div className="row">
            <p className="col-lg-6">
                {t("offer.create_offer.project_program_title")} &nbsp;
                <i>({formData.projectProgram} €)</i>
            </p>
                <textarea
                    name={"estimate_program"}
                    value={formData.projectProgram}
                    onChange={(e) => dispatch({
                        type: "SET_PROJECT_PROGRAM",
                        value: e.target.value,
                    })}
                    rows={6}
                    style={{width: "100%", fontSize: "19px"}
                    }
                />

        </div>
    </>)
}

export default ParametersPdfForm;
