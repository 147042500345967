import {Link, useNavigate} from "react-router-dom";
import LordIcon from "../../../../../components/ui/LordIcon";
import {Colors} from "../../../../../components/theme";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {getClientsListFirmNameAPI} from "../../../../../services/api/clients";
import DarkCard from "../../../../../components/card/DarkCard";
import {DetailsStyle, ElementStyle, FieldStyle, NoteParagraphStyle, NoteStyle} from "../../../../../layouts/styles";


const NoteParagraph = styled.p `
    font-size: 0.9rem;
    margin: 5px 0px 0px 0px;
`

const getClientsList = async () => {
    try {
        const clientsData = await getClientsListFirmNameAPI()
        return clientsData.data
    } catch (e) {
        console.log("Failed to download clients list", e);
        return
    }
}


const ClientsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [clients, setClients] = useState([])

    useEffect(() => {
        sessionStorage.removeItem('options-in-session')
        const getData = async () => {
            const response = await getClientsList()
            if (response) {
                setClients(response)
            } else {
                // alert popup
                console.log("Failed to download clients list.")
            }
        }
        getData()
        sessionStorage.removeItem('options-in-session')
    }, [])

    const handleClick = (client_id) => {
        navigate("details/"+client_id)
    }

    return(<>
        <DarkCard classNames='mb-3 row'>
            Breadcrumbs: Clients list
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
        <div className="row">
            <div className="col-sm-8">
                <Link className="addGroup" to={'create'}>
                    <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                    <span className="ps-2">{t("common.button.add_client")}</span>
                </Link>
            </div>
            <div className="col-sm-4 mt-3 mt-sm-0">
                <input style={{width: '100%'}} placeholder="Search" disabled></input>
            </div>
        </div>
        <div className="mt-4">
            {clients.map(client =>
                <ElementStyle className={"d-flex flex-column"} key={client.id}>
                    <DetailsStyle className="d-flex flex-row flex-wrap flex-md-nowrap" />
                    <div className="d-flex flex-row flex-wrap flex-md-nowrap py-1" style={{width: '95%'}}>
                        <FieldStyle width="70%" >
                            <a onClick={() => handleClick(client.id)}><b>{client.first_name} {client.last_name}</b></a>
                        </FieldStyle>
                        <FieldStyle width="70%">
                            <a href={"tel:" + client.phoneCountry + client.telephone}>{client.phoneCountry} {client.telephone}</a>
                        </FieldStyle>
                        <FieldStyle>
                            <a href={"mailto:"+ client.email}> {client.email} </a>
                        </FieldStyle>
                        <FieldStyle width="40%">
                            <ul className="my-0">
                            {client.firms.map(el =>
                                <li key={el.pk} className="pe-2" style={{display:'inline'}}> {el.firm_name}</li>
                            )}
                            </ul>
                        </FieldStyle>
                        
                    </div>
                    {client.note && <NoteStyle>
                                        <NoteParagraphStyle>
                                            <b>{t("profile.note")}:</b> {client.note}
                                        </NoteParagraphStyle>
                                    </NoteStyle>
                    }
                </ElementStyle>
            )}

        </div>
        </DarkCard>
    </>)
}

export default ClientsList