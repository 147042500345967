import {Route, Routes} from "react-router-dom";
import ProjectCard from "../Projects/Projects/ProjectCard";
import ProjectsList from "../Projects/Projects/ProjectsList";

const ProjectsRoutes = () => {
    return (<>
        <Routes >
            <Route exact path={"/projects/in-preparation"} element={<ProjectsList />}/>
            <Route exact path={"/projects/in-preparation/:id"} element={<ProjectCard />}/>
        </Routes>
    </>)
}

export default ProjectsRoutes