import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import Select from "react-select";
import select_styles from "../../../../../../layouts/elements";
import {Button} from "../../../../../../components/ui/Button";
import {Colors} from "../../../../../../components/theme";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {positionsListApi, savePrintingOnDocumentAPI} from "../../../../../../services/api/user";
import PopupMessage from "../../../../../../components/ui/PopupMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {position_admin, position_creative} from "../../../../../../helpers/dictionary/aplication";


const opt_list = {value: 0, label: "-----"}
const popupInit = {show: false, title: '', variant: 'error', message: ''}
const ImageStyle = {
    width: '200px',
    height: 'auto',
};

const PrintingOnDocumentForm = ({setPrinting,
                                adminPersonsOpt,
                                creativePersonsOpt,
                                printingData,
                                setPrintingData}) => {
    const {t} = useTranslation()
    const [positions, setPositions] = useState({value: "", label: ""})
    const [adminSel, setAdminSel] = useState({opt_list})
    const [creativeSel, setCreativeSel] = useState({opt_list})
    const [logo, setLogo] = useState("")
    const [popup, setPopup] = useState(popupInit);

    useEffect(() => {
        const getPositionsNames = async () => {
            const positionsData = await positionsListApi()
            if (positionsData.status === 200) {
                const pos = {
                    [position_admin]: positionsData.data.find(el => el.label === position_admin),
                    [position_creative]: positionsData.data.find(el => el.label === position_creative)
                }
                setPositions(pos)
            } else {
                console.log("failed to download positions names data.")
            }
        }

        if (printingData[position_admin]) {
            setAdminSel({
                value: printingData[position_admin].pk_person,
                label: printingData[position_admin].cn
            })
            setCreativeSel({
                value: printingData[position_creative].pk_person,
                label: printingData[position_creative].cn
            })
        }
        setLogo(printingData.logo)
        getPositionsNames()
    }, [])

    const updateEvent = async (event) => {
        event.preventDefault()

        const data = {
            [position_admin]: {
                "pk_position": positions[position_admin].value,
                "pk_person": adminSel.value
            },
            [position_creative]: {
                "pk_position": positions[position_creative].value,
                "pk_person": creativeSel.value
            },
        }

        const formData = new FormData()
        formData.append("admin_pk_position", positions[position_admin].value)
        formData.append("admin_pk_person", adminSel.value)
        formData.append("creative_pk_position", positions[position_creative].value)
        formData.append("creative_pk_person", creativeSel.value)

        // if (((typeof logo === "object") || (typeof logo === "File")) && (logo.hasOwnProperty("name"))) {
        if (typeof logo === "object") {
            formData.append('logo', logo, logo.name)
        }
        if (logo === "remove") {
            formData.append('logo', logo)
        }
        const response = await savePrintingOnDocumentAPI(formData)
        if (response.status === 200) {
            setPopup({show: true, title: t("popups.save_data.title"), variant: 'success', message: t("popups.save_data.message")})
        } else {
            setPopup({show: true, title: t("popups.error_save_data.title"), variant: 'error', message: t("popups.error_save_data.message")})
        }
    }

    const selectedHandle = (val, fieldName) => {
        if (fieldName === position_admin) {
            setAdminSel(val)
        } else setCreativeSel(val)
    }

    const cancelHandler = () => {
        setPrinting(false)
    }

    const handlePopup = (obj) => {
        if (popup.variant === 'success') {
            setPrintingData({
                [position_admin]: {
                    pk_position: positions[position_admin].value,
                    pk_person: adminSel.value,
                    cn: adminSel.label
                },
                [position_creative]: {
                    pk_position: positions[position_creative].value,
                    pk_person: creativeSel.value,
                    cn: creativeSel.label
                },
                logo: logo,
            })
            setPrinting(false)
        }
        setPopup(popupInit)
    }

    const logo_src = () => {
        if (logo === 'remove') {return ""}
        return typeof logo==="object" ? URL.createObjectURL(logo) : logo
    }

    return(<>
        {popup.show
        ? <PopupMessage
            setPopup={handlePopup}
            variant={popup.variant}
            title={popup.title}
            message={popup.message}
            okBtn={t("common.button.ok")}
        />
        :
        <form name="update-form" className="col-xl-4" onSubmit={updateEvent}>
            <div className="row pb-2">
                <InputWrapperDiv>
                    <div className='customSelectParent'>
                        {t("asbl.admin_on_doc")}
                        <Select
                            name='admin'
                            options={adminPersonsOpt}
                            value={adminPersonsOpt.find(el => el.value === adminSel.value)}
                            onChange={val => selectedHandle(val, position_admin)}
                            styles={select_styles}
                            unstyled
                            required={"required"}
                        />
                    </div>
                </InputWrapperDiv>
            </div>

            <div className="row pb-2">
                <InputWrapperDiv>
                    <div className='customSelectParent'>
                        {t("asbl.creative_on_doc")}
                        <Select
                            name='creative'
                            options={creativePersonsOpt}
                            value={creativePersonsOpt.find(el => el.value === creativeSel.value)}
                            onChange={val => selectedHandle(val, position_creative)}
                            styles={select_styles}
                            unstyled
                            required={"required"}
                        />
                    </div>
                </InputWrapperDiv>
            </div>

            <div className="row pb-2">
                <InputWrapperDiv className="px-2 mt-2">
                    <p>{t("asbl.actual_logo_title")}</p>
                    {(logo) &&
                        <div className="" style={{position:'relative', maxWidth:'250px'}}>
                            <a className="ms-3" style={{fontSize:'20px', position:'absolute', right: 0}} name="editName"
                             onClick={e => setLogo("remove")} >
                                <FontAwesomeIcon icon={faCircleXmark}/>
                            </a>
                            <img src={logo_src()} alt='' style={ImageStyle} />
                        </div>
                    }

                    <p>{t("asbl.new_logo_title")}</p>
                    <input
                        type="file"
                        name={'logo'}
                        onChange={e => setLogo(e.target.files[0])}
                    />
                </InputWrapperDiv>
            </div>

            <div>
                <Button
                    variant="round"
                    onClick={cancelHandler}
                    styling="bordered"
                    className={'me-3'}
                >
                    {t("common.button.cancel")}
                </Button>
                <Button
                    type="submit"
                    variant='round'
                    style={{backgroundColor:`${Colors.red}`}}
                >
                    {t("common.button.save")}
                </Button>
            </div>
        </form>
        }
    </>)
}

export default PrintingOnDocumentForm

