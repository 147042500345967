// import styled from "styled-components";
import DarkCard from "../../../../../../components/card/DarkCard";
import LightCard from "../../../../../../components/card/LightCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import { useTranslation } from "react-i18next";
import RatesSettings from "./RatesSettings";
import EventsSettings from "./EventsSettings";
import OtherCostsSettings from "./OtherCostsSettings";
import React, {useEffect, useState} from "react";
import {abslSaveSettingsAPI, getProjectSettings} from "../../../../../../services/api/user";
import { v4 as uuidv4 } from 'uuid';
import {Button} from "../../../../../../components/ui/Button";
import PopupMessage from "../../../../../../components/ui/PopupMessage";
import {useNavigate} from "react-router-dom";
import { Colors } from "../../../../../../components/theme";


// const TotalPriceStyle = styled.p `
//     font-size: 1.5rem;
//     font-weight: bold;
//     width: 100%;
//     @media (max-width: 575px) {
//         font-size: 1.8rem;
//     }
// `

function AsblSettings () {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const ratesField = {
        client_rate_kzm_rehearsal: {key: 'ckmzreh', name: 'client_rate_kzm_rehearsal', value: '', label: `${t("events.rehearsal")} =`},
        client_rate_kzm_raccord: {key: 'ckmzrac', name: 'client_rate_kzm_raccord', value: '', label: `${t("events.raccord")} =`},
        client_rate_kzm_concert: {key :'ckmzcon', name: 'client_rate_kzm_concert', value: '', label: `${t("events.concert")} =`},
        client_rate_mus_rehearsal: {key: 'cmusreh', name: 'client_rate_mus_rehearsal', value: '', label: `${t("events.rehearsal")} =`},
        client_rate_mus_raccord: {key: 'cmusrac', name: 'client_rate_mus_raccord', value: '', label: `${t("events.raccord")} =`},
        client_rate_mus_concert: {key: 'cmuscon', name: 'client_rate_mus_concert', value: '', label: `${t("events.concert")} =`},
        estro_rate_kzm_rehearsal: {key: 'ekmzreh', name: 'estro_rate_kzm_rehearsal', value: '', label: `${t("events.rehearsal")} =`},
        estro_rate_kzm_raccord: {key: 'ekmzrac', name: 'estro_rate_kzm_raccord', value: '', label: `${t("events.raccord")} =`},
        estro_rate_kzm_concert: {key: 'ekzmcon', name: 'estro_rate_kzm_concert', value: '', label: `${t("events.concert")} =`},
        estro_rate_mus_rehearsal: {key: 'emusreh', name: 'estro_rate_mus_rehearsal', value: '', label: `${t("events.rehearsal")} =`},
        estro_rate_mus_raccord: {key: 'emusrac', name: 'estro_rate_mus_raccord', value: '', label: `${t("events.raccord")} =`},
        estro_rate_mus_concert: {key: 'emuscon', name: 'estro_rate_mus_concert', value: '', label: `${t("events.concert")} =`},
    };

    const other_entries = {
            transport: [],
            archiving: [],
            administration: [],
    };

    const [event, setEvent] = useState({rehearsal: "", raccord: ""});
    const [rates, setRates] = useState(ratesField);
    const [otherEntries, setOtherEntries] = useState([])
    const [popup, setPopup] = useState({
        view: false,
        variant: 'error',
        title: "popups.save_data.title",
        message: "popups.save_data.message",
    });

    const transEntry = (val) => {
        switch (val) {
            case 'Musicians':
                return val // t("transport.musicians_title")
            case 'Bow strokes 1h':
                return t("archiving.asbl_settings.bowStrokes_entry_title")
            case '1 Copy':
                return t("archiving.asbl_settings.copy_entry_title")
            case 'Filling 1h':
                return t("archiving.asbl_settings.filling_entry_title")
            case 'Operating costs':
                return t("administration.operating_costs_title")
            case 'Treasurer':
                return t("administration.treasurer_title")
            default:
                return val;
        }
    }

    const settingsItem = (el) => {
        return {
            key: el.id,
            description: transEntry(el.description),
            client_cost: el.client_cost,
            estro_cost: el.estro_cost,
            readOnly: el.readOnly,
            display_type: el.display_type,
        }
    }

    useEffect(() => {
        const get_settings = async () => {
            try {
                const responseSettings = await getProjectSettings();
                const settingsResp = responseSettings.data;
                setEvent({
                    rehearsal: settingsResp.rehearsal_duration,
                    raccord: settingsResp.raccord_duration,
                });

                ratesField.client_rate_kzm_rehearsal.value = settingsResp.client_rate_kzm_rehearsal;
                ratesField.client_rate_kzm_raccord.value = settingsResp.client_rate_kzm_raccord;
                ratesField.client_rate_kzm_concert.value = settingsResp.client_rate_kzm_concert;
                ratesField.client_rate_mus_rehearsal.value = settingsResp.client_rate_mus_rehearsal;
                ratesField.client_rate_mus_raccord.value = settingsResp.client_rate_mus_raccord;
                ratesField.client_rate_mus_concert.value = settingsResp.client_rate_mus_concert;
                ratesField.estro_rate_kzm_rehearsal.value = settingsResp.estro_rate_kzm_rehearsal;
                ratesField.estro_rate_kzm_raccord.value = settingsResp.estro_rate_kzm_raccord;
                ratesField.estro_rate_kzm_concert.value = settingsResp.estro_rate_kzm_concert;
                ratesField.estro_rate_mus_rehearsal.value = settingsResp.estro_rate_mus_rehearsal;
                ratesField.estro_rate_mus_raccord.value = settingsResp.estro_rate_mus_raccord;
                ratesField.estro_rate_mus_concert.value = settingsResp.estro_rate_mus_concert;

                setRates(ratesField);

                settingsResp.other_cost_type.map( el => {
                    switch (el.cost_type) {
                        case 'transport':
                            other_entries.transport.push(settingsItem(el))
                            break;
                        case 'archiving':
                            other_entries.archiving.push(settingsItem(el))
                            break;
                        case 'administration':
                            other_entries.administration.push(settingsItem(el));
                            break;
                        default:
                            return {}
                    };
                })
                setOtherEntries(other_entries)
            } catch(ex) {
                console.log("Failed to download settings")
            }
        };
        get_settings();
    }, []);

    const changeUnitInfo = (itemObj, itemType) => { // itemType = 'transport'
        const elementItemType = structuredClone(otherEntries[itemType]);

        function withOutElement(el) {
            if (el.key === itemObj.key ) {
                return true
            }
            return false
        };
        const newElementIndex = elementItemType.findIndex(withOutElement);
        elementItemType.splice(newElementIndex, 1, itemObj)

        const newEntry = {
            ...otherEntries,
            [itemType]: elementItemType
        }

        setOtherEntries(newEntry)
    };

    const addMoreHandler = (unitType) => {
        const newItem = {
            key: uuidv4(),
            description: '',
            client_cost: '0.00',
            estro_cost: '0.00',
            readOnly: false,
            display_type: "without"
        };
        const newArrayElement = otherEntries[unitType];
        newArrayElement.push(newItem)

        const newEntry = {
            ...otherEntries,
            [unitType]: newArrayElement
        }
        setOtherEntries(newEntry)
    };

    const handlePopup = (obj) => {
        setPopup(obj.show);
        if (obj.decision) {
            navigate('/asbl/settings')
        }
    }

    const saveHandler = async () => {
        const data = {['other_cost_type']: []};

        Object.entries(rates).forEach(([key, val]) => {
            data[key] = val.value
        });

        Object.entries(event).forEach(([key, val]) => {
            data[key] = val
        });

        const types = Object.keys(otherEntries)

        types.forEach((el) => {
            otherEntries[el].forEach((obj) => {
                data['other_cost_type'].push(
                    {
                        id: obj.key,
                        description: obj.description,
                        client_cost: obj.client_cost,
                        estro_cost: obj.estro_cost,
                        cost_type: el,
                        readOnly: obj.readOnly,
                        display_type: obj.display_type,
                    }
                )
            })
        });

        try {
            const response = await abslSaveSettingsAPI(data);
            if (response.status !== 200 && Object.keys(response).length > 0) {
                setPopup({
                    view: true,
                    variant: 'error',
                    title: "popups.error_save_data.title",
                    message: "popups.error_save_data.message",
                })

            } else if (response.status === 200) {
                setPopup({
                    view: true,
                    variant: 'success',
                    title: "popups.save_data.title",
                    message: "popups.save_data.message",
                })
            }
        } catch(ex) {
            console.error("Nie udalo sie zapisac danych...", ex);
            setPopup({
                view: true,
                variant: 'error',
                title: "popups.error_save_data.title",
                message: "popups.error_save_data.message",
            })
            return
        };
    };

    const removeItem = (itemKey, unitType) => {
        // usun z otherEntries z unitType wpis z key=itemKey
        const entryClone = structuredClone(otherEntries)
        const indexDel =entryClone[unitType].findIndex(el => el.key === itemKey)
        entryClone[unitType].splice(indexDel, 1)
        setOtherEntries(entryClone)
    };

    return (
        popup.view
        ? <PopupMessage
                setPopup={handlePopup}
                variant={popup.variant}
                title={t(popup.title)}
                message={t(popup.message)}
                okBtn={t("common.button.ok")}
            />
        :<>
            <DarkCard classNames='mb-3'>
                Breadcrumbs: ASBL -> Settings
            </DarkCard>
            <DarkCard classNames='mb-3'>
                <LightCard classNames='mb-3'>
                    <RedHeader title={t("events.settings_title")} />
                    <EventsSettings event={event} setEvent={setEvent} />
                </LightCard>

                <LightCard classNames='mb-3'>
                    <RatesSettings rates={rates} setRates={setRates} />
                </LightCard>
                <LightCard classNames='mb-3'>
                    <div className="row">
                        <div className="col-md-6">
                            <RedHeader title={t("transport.title")} />
                            <OtherCostsSettings
                                otherEntries={otherEntries.transport}
                                changeUnitInfo={changeUnitInfo}
                                addMoreHandler={addMoreHandler}
                                unitType={'transport'}
                                removeItem={removeItem}
                            />
                        </div>
                        <div className="col-md-6">
                            <RedHeader title={t("archiving.title")} />
                            <OtherCostsSettings
                                otherEntries={otherEntries.archiving}
                                changeUnitInfo={changeUnitInfo}
                                addMoreHandler={addMoreHandler}
                                unitType={'archiving'}
                                removeItem={removeItem}
                            />
                        </div>
                    </div>
                </LightCard>
                <LightCard classNames='mb-3'>
                    <div className="row">
                        <div className="col-md-6">
                            <RedHeader title={t("administration.title")} />
                            <OtherCostsSettings
                                otherEntries={otherEntries.administration}
                                changeUnitInfo={changeUnitInfo}
                                addMoreHandler={addMoreHandler}
                                unitType={'administration'}
                                removeItem={removeItem}
                            />
                        </div>
                    </div>
                </LightCard>
                <LightCard classNames='mb-3'>
                    <div className="row">
                        <div className="col-md-6">
                            <Button
                                variant="round"
                                style={{backgroundColor:`${Colors.green}`}}
                                type='submit'
                                onClick={saveHandler}>
                                {t("common.button.save")}
                            </Button>
                        </div>
                    </div>
                </LightCard>
            </DarkCard>
        </>
)
};
export default AsblSettings;
