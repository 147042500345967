import styled from "styled-components";
import DarkCard from "../../../../../../components/card/DarkCard";
import LightCard from "../../../../../../components/card/LightCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import Staff from "../../../../../../components/project/Staff";
import Events from "../../../../../../components/project/Events";
import { useTranslation } from "react-i18next";
import Rates from "../../../../../../components/project/Rates";
import RentingCosts from "../../../../../../components/project/RentingCosts";
import TransportCosts from "../../../../../../components/project/TransportCosts";
import AdministrationCosts from "../../../../../../components/project/AdministrationCosts";
import ArchivingCosts from "../../../../../../components/project/ArchivingCosts";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import {
	getInstrumentsStaffListAPI,
	getProjectSettings,
} from "../../../../../../services/api/user";
import {
	archiving_entry,
	archiving_entry_obj,
	archiving_entry_revers,
	name_list_archiving,
	saveCalculation,
	updateCalculation,
	staffValueSum,
	sumAdminCostDetails,
	sumAdminCosts,
	deleteCalculation,
} from "../helpers/func_helpers";
import {
	ratesCalculation,
	sumRatesClientCost,
} from "../helpers/calculationTotal";
import { calcLocationListAPI } from "../../../../../../services/api/locations";
import {
	getNameColumnTypeStaff,
	getTypeListStaff,
	musiciansCount,
} from "../../../../../../helpers/tools";
import { Button } from "../../../../../../components/ui/Button";
import PopupForm from "../../../../../../components/ui/PopupForm";
import {
	calculationDetailAPI,
	calculationEditAPI,
} from "../../../../../../services/api/calculator";
import { useNavigate, useParams } from "react-router-dom";
// atrybut "edit" -> caly wiersz jest edytowalny lub nie (desc, costs...)
// atrybut "readOnly" -> pole description jest nieedytowalne / wartosc arybutu brana z db
import { Colors } from "../../../../../../components/theme";
import {Input, InputWrapperDiv} from "../../../../../../components/ui/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheckCircle, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import SlideButton from "../../../../../../components/ui/SlideButton";
// import {useSessionStorage} from "usehooks-ts";

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

const TotalPriceStyle = styled.p`
	font-size: 1.5rem;
	font-weight: bold;
	width: 100%;
	@media (max-width: 575px) {
		font-size: 1.8rem;
	}
`;

const eventsFieldsInit = {
	rehearsals: 0,
	raccords: 0,
	concerts: 0,
};

function Calculator(props)  {
	const { t } = useTranslation();
	const params = useParams();
	const [staff, setStaff] = useState({});
	const [details, setDetails] = useState(false);
	const [events, setEvents] = useState(eventsFieldsInit);
	const [eventDetail, setEventDetail] = useState([]);
	const [clientKzm, setClientKzm] = useState({
		concerts: 0,
		raccords: 0,
		rehearsals: 0,
	});
	const [clientMus, setClientMus] = useState({
		concerts: 0,
		raccords: 0,
		rehearsals: 0,
	});
	const [estroKzm, setEstroKzm] = useState({
		concerts: 0,
		raccords: 0,
		rehearsals: 0,
	});
	const [estroMus, setEstroMus] = useState({
		concerts: 0,
		raccords: 0,
		rehearsals: 0,
	});
	const [renting, setRenting] = useState([
		{
			id: "X1",
			description: "X",
			client_cost: 0,
			estro_cost: 0,
			cost_type: "renting",
			display_type: "X",
		},
	]);
	const [administration, setAdministration] = useState([
		{
			id: "X1",
			description: "X",
			client_cost: 0,
			estro_cost: 0,
			cost_type: "administration",
			display_type: "X",
		},
	]);
	const [archiving, setArchiving] = useState([
		{
			id: "X1",
			description: "X",
			client_cost: 0,
			estro_cost: 0,
			entity: "pcs",
			cost_type: "archiving",
			display_type: "X",
			name: "X",
		},
	]);
	const [transp30km, setTransp30km] = useState(false);
	const [transport, setTransport] = useState([
		{
			id: "X1",
			description: "X",
			name: "X",
			client_cost: 0,
			estro_cost: 0,
			cost_type: "transport",
			edit: false,
			display_type: "X",
		},
	]);
	const [project30kmAway, setProject30kmAway] = useState({
		description: "Musicians",
		client_cost: 0.0,
		estro_cost: 0.0,
	});
	const [conductor30kmAway, setConductor30kmAway] = useState({
		description: "Conductor",
		client_cost: 0.0,
		estro_cost: 0.0,
	});
	const [popup, setPopup] = useState({ show: false, saveUs: false });
	const compositionOptionsInit = [
		{value: "Tutti", label: t("effectif.composition") + "*", color: '#00ace6'},
		// {value: 'Tutti', label: 'Tutti', color: '#00ace6', isDisabled: true}
	];
	const [compositionOptions, setCompositionOptions] = useState(compositionOptionsInit);
	const [eventDuration, setEventDuration] = useState({
		rehearsal_duration: 0,
		raccord_duration: 0,
	});
	const [kzmProject, setKzmProject] = useState(true);
	const [locations, setLocations] = useState([]);
	const [conductor, setConductor] = useState("");
	const [calculationName, setCalculationName] = useState("");
	const [calculNameEditInput, setCalculNameEditInput] = useState({
		state: false,
		name: "",
	});
	const [calculationNotes, setCalculationNotes] = useState("");
	const [administrationReset, setAdministrationReset] = useState(false);
	// const [projectSumData, setProjectSumData] = useSessionStorage("calculateSumData", null);
	const navigate = useNavigate();
	const [session_data, setSessionData] = useState(JSON.parse(sessionStorage.getItem("CALCULATION")))

	const transp30kmMusicians = transportClone => {
		const musiciansSum = musiciansCount(staff);
		const musiciansIndex = transportClone.findIndex(el => el.id === "9999");

		if (musiciansIndex > -1) {
			const item9999 = {
				id: "9999",
				description:
					musiciansSum > 0
						? `${project30kmAway.description} (x ${musiciansSum})`
						: project30kmAway.description,
				name: project30kmAway.description,
				client_cost: parseFloat(
					project30kmAway.client_cost * musiciansSum
				).toFixed(2),
				estro_cost: parseFloat(
					project30kmAway.estro_cost * musiciansSum
				).toFixed(2),
				cost_type: "transport",
				edit: false,
				display_type: "fullro",
			};
			transportClone.splice(musiciansIndex, 1, item9999); // usun Musician - id=9999
		}
		// conductor / jesli nie istnieje w transport to trzeba dodac
		if (conductor > 0 && transp30km) {
			const item9988 = {
				id: "9988",
				description:
					conductor > 0
						? `${conductor30kmAway.description} (x ${conductor})`
						: conductor30kmAway.description,
				name: conductor30kmAway.description,
				client_cost: parseFloat(
					conductor30kmAway.client_cost * conductor
				).toFixed(2),
				estro_cost: parseFloat(
					conductor30kmAway.estro_cost * conductor
				).toFixed(2),
				cost_type: "transport",
				edit: false,
				display_type: "fullro",
			};
			const conductorIndex = transportClone.findIndex(el => el.id === "9988");
			if (conductorIndex > -1) {
				transportClone.splice(conductorIndex, 1, item9988); // usun Musician - id=9999
			} else {
				// utworz item z conductor
				transportClone.unshift(item9988);
			}
		}
		setTransport(transportClone);
	};

	useEffect(() => {
		const transportClone = structuredClone(transport);
		if (transp30km) {
			const musiciansSum = musiciansCount(staff);
			transportClone.unshift({
				id: "9999",
				description:
					musiciansSum > 0
						? `${project30kmAway.description} (x ${musiciansSum})`
						: project30kmAway.description,
				name: project30kmAway.description,
				client_cost: parseFloat(
					project30kmAway.client_cost * musiciansSum
				).toFixed(2),
				estro_cost: parseFloat(
					project30kmAway.estro_cost * musiciansSum
				).toFixed(2),
				cost_type: "transport",
				edit: false,
				display_type: "fullro",
			});
			// dodaj conductor na poczatek
			if (conductor > 0) {
				transportClone.unshift({
					id: "9988",
					description: `${conductor30kmAway.description} (x ${conductor})`,
					name: conductor30kmAway.description,
					client_cost: parseFloat(
						conductor30kmAway.client_cost * conductor
					).toFixed(2),
					estro_cost: parseFloat(
						conductor30kmAway.estro_cost * conductor
					).toFixed(2),
					cost_type: "transport",
					edit: false,
					display_type: "fullro",
				});
			}
		} else {
			const indexDel = transportClone.findIndex(el => el.id === "9999");

			if (indexDel > -1) {
				transportClone.splice(indexDel, 1);
			}
			// usun conductor z listy
			const indexConductorDel = transportClone.findIndex(
				el => el.id === "9988"
			);
			if (indexConductorDel > -1) {
				transportClone.splice(indexConductorDel, 1);
			}
		}
		setTransport(transportClone);
	}, [transp30km]);

	useEffect(() => {
		// return [...new Set(arr)];
		const selectedStaff = getTypeListStaff(staff);
		setCompositionOptions(selectedStaff);

		const transportClone = structuredClone(transport);
		transp30kmMusicians(transportClone);
	}, [staff]);

	useEffect(() => {
		const transportClone = structuredClone(transport);
		transp30kmMusicians(transportClone);
	}, [conductor]);

	useEffect(() => {
		const getCalculationDetail = async () => {
			try {
				const resp_calculation = await calculationDetailAPI(params.key);
				const resp_details = resp_calculation.data.data;
				setStaff(resp_details.staff);
				setEvents(resp_details.events);
				const event_detail = resp_calculation.data.eventDetails.map(el => {
					return {
						...el,
						eventDate: new Date(el.eventDate),
					};
				});
				setEventDetail(event_detail);
				setDetails(resp_details.details);
				setAdministration(resp_details.administration);

				const repear_archiving = resp_details.archiving.map(el => {
					if (el.display_type !== "X") {
						return {
							...el,
							"display_type": "archivro",
							"cost_type": "archiving",
							"readOnly": true,
							"edit": false,
							"name": archiving_entry_revers(el.description),
						};
					}
					return el;
				});

				setArchiving(repear_archiving);
				setClientKzm(resp_details.clientKzm);
				setClientMus(resp_details.clientMus);
				setConductor(resp_details.conductor);
				setEstroKzm(resp_details.estroKzm);
				setEstroMus(resp_details.estroMus);
				setEventDuration(resp_details.eventDuration);
				setKzmProject(resp_details.kzmProject);
				setRenting(resp_details.renting);
				setProject30kmAway(resp_details.project30kmAway);
				setConductor30kmAway(resp_details.conductor30kmAway);
				setTransp30km(resp_details.transp30km);
				setTransport(resp_details.transport);
				setCalculationName(resp_calculation.data.name);
				setCalculNameEditInput({
					state: false,
					name: resp_calculation.data.name,
				});
				setCalculationNotes(
					resp_details.calculationNotes ? resp_details.calculationNotes : ""
				);
			} catch (e) {
				console.error(e);
				navigate("/sales/saved_calculations");
			}
			// location options
			try {
				const resplist = await calcLocationListAPI();
				const data = resplist.data.qs.map(el => {
					return {
						value: el.name,
						label: el.name,
						pk: el.pk,
					};
				});
				data.unshift({
					value: "to_define",
					label: t("location.to_define"),
					pk: "",
				});
				setLocations(data);
			} catch (ex) {
				console.log("Failed to download locations list.", ex);
			}
			if (session_data) {

			}
		};

		const get_settings = async () => {
			try {
				const responseSettings = await getProjectSettings();
				const settingsResp = responseSettings.data;
				const other_cost_type = settingsResp.other_cost_type;
				const resultDuration = {
					rehearsal_duration: +settingsResp.rehearsal_duration,
					raccord_duration: +settingsResp.raccord_duration,
				};
				const result = other_cost_type.filter(
					type => type.cost_type === "renting"
				);
				const resultAdministration = other_cost_type.filter(
					type => type.cost_type === "administration"
				);
				const resultAdmin = resultAdministration.map(el => ({
					...el,
					["edit"]: true,
				}));

				const resultArch = other_cost_type.filter(
					type => type.cost_type === "archiving"
				);

				const archiving_data = [];
				name_list_archiving.forEach(name => {
					const result_item = resultArch.find(el => el.description === name);
					if (result_item) {
						result_item.name = name;
						result_item.description = archiving_entry(t, name).desc;
						result_item.display_type = "archivro";
						result_item.amount = 0;
						result_item.entity = archiving_entry(t, name).entity;
						result_item.edit = false;
						result_item.unit_client_cost = result_item.client_cost;
						result_item.unit_estro_cost = result_item.estro_cost;
						result_item.client_cost = "0.00";
						result_item.estro_cost = "0.00";
						archiving_data.push(result_item);
					}
				});
				// reszta z resultArch
				const result_rest_name = resultArch.filter(
					el => !name_list_archiving.includes(el.name)
				);
				const rest_resultArch_data = result_rest_name.map(el => {
					return archiving_entry_obj(t, el, el.display_type);
				});
				archiving_data.push(...rest_resultArch_data);

				const resultTransp = other_cost_type.filter(
					type => type.cost_type === "transport"
				);
				const resultTransport = resultTransp.map(el => ({
					...el,
					["edit"]: true,
				}));
				const musiciansIndex = resultTransport.findIndex(
					el => el.description.toLowerCase() === "musicians"
				);
				if (musiciansIndex > -1) {
					const projectItem = resultTransport[musiciansIndex];

					setProject30kmAway({
						// utrzymanie domyslnych z db wartosci dla musicians
						description: projectItem.description,
						client_cost: projectItem.client_cost,
						estro_cost: projectItem.estro_cost,
						name: projectItem.description,
					});
					resultTransport.splice(musiciansIndex, 1);
				}
				// dodaj conductor dane do stanu conductor30kmAway
				const conductorIndex = resultTransport.findIndex(
					el => el.description.toLowerCase() === "conductor"
				);
				if (conductorIndex > -1) {
					const conductorItem = resultTransport[conductorIndex];
					setConductor30kmAway({
						description: conductorItem.description,
						client_cost: conductorItem.client_cost,
						estro_cost: conductorItem.estro_cost,
						name: conductorItem.description,
					});
					resultTransport.splice(conductorIndex, 1);
				}
				setEvents(eventsFieldsInit);
				setDetails(false);
				setEventDetail([]);
				setTransp30km(false);
				setTransport(resultTransport);
				setRenting(result);
				setEventDuration(resultDuration);
				setArchiving(archiving_data);
				setConductor("");
				const resultAdminFirstEntry = {
					id: "ADMIN_WORK",
					description: "Administrator work",
					client_cost: "0.00",
					estro_cost: "0.00",
					cost_type: "administration",
					edit: false,
					display_type: "fullro",
				};
				resultAdmin.unshift(resultAdminFirstEntry);
				setAdministration(resultAdmin);
				setClientKzm({
					rehearsals: parseFloat(settingsResp.client_rate_kzm_rehearsal),
					raccords: parseFloat(settingsResp.client_rate_kzm_raccord),
					concerts: parseFloat(settingsResp.client_rate_kzm_concert),
				});
				setClientMus({
					rehearsals: parseFloat(settingsResp.client_rate_mus_rehearsal),
					raccords: parseFloat(settingsResp.client_rate_mus_raccord),
					concerts: parseFloat(settingsResp.client_rate_mus_concert),
				});
				setEstroKzm({
					rehearsals: parseFloat(settingsResp.estro_rate_kzm_rehearsal),
					raccords: parseFloat(settingsResp.estro_rate_kzm_raccord),
					concerts: parseFloat(settingsResp.estro_rate_kzm_concert),
				});
				setEstroMus({
					rehearsals: parseFloat(settingsResp.estro_rate_mus_rehearsal),
					raccords: parseFloat(settingsResp.estro_rate_mus_raccord),
					concerts: parseFloat(settingsResp.estro_rate_mus_concert),
				});
				setCalculationNotes("");
			} catch (ex) {
				console.log("Failed to download settings", ex);
			}
			try {
				const responseInstrumentsStaff = await getInstrumentsStaffListAPI();
				const instrStaffResp = responseInstrumentsStaff.data;
				const violinOld = instrStaffResp["Violin"];
				instrStaffResp["Violin1"] = {
					...violinOld,
					name: "Violin1",
					label: "effectif.Violin1",
				};
				instrStaffResp["Violin2"] = {
					...violinOld,
					name: "Violin2",
					label: "effectif.Violin2",
				};
				delete instrStaffResp["Violin"];
				Object.keys(instrStaffResp).forEach(el => {
					// wyznacz oznaczenie typu instrumentu
					const instrTypeName = getNameColumnTypeStaff(el);
					instrStaffResp[el].type = instrTypeName;
				});
				setStaff(instrStaffResp);
			} catch (ex) {
				console.log("Failed to download instruments staff list.");
			}
			try {
				const resplist = await calcLocationListAPI();
				const data = resplist.data.qs.map(el => {
					return {
						value: el.name,
						label: el.name,
						pk: el.pk,
					};
				});
				data.unshift({
					value: "to_define",
					label: t("location.to_define"),
					pk: "",
				});
				setLocations(data);
			} catch (ex) {
				console.log("Failed to download locations list.", ex);
			}
		};

		if (params.hasOwnProperty("key")) {
			getCalculationDetail();
		} else {
			get_settings();
		}
	}, [params.key]);

	useEffect(() => {
		const adminClone = structuredClone(administration);
		//zmiana w administration work
		const adminIndex = adminClone.findIndex(el => el.id === "ADMIN_WORK");

		if (adminIndex > -1) {
			// przelicz kwote administrator work
			let data = {};
			if (details) {
				data = {
					events: eventDetail,
					client_rehearsals: clientMus.rehearsals,
					client_raccords: clientMus.raccords,
					client_concerts: clientMus.concerts,
					rehearsals_duration: eventDuration.rehearsal_duration,
					raccords_duration: eventDuration.raccord_duration,
				};
				const adminItem = { ...adminClone[adminIndex] };
				adminItem.client_cost = sumAdminCostDetails(data);
				adminClone.splice(adminIndex, 1, adminItem);
				setAdministration(adminClone);
			} else {
				data = {
					count_rehearsals: events.rehearsals,
					count_raccords: events.raccords,
					count_concerts: events.concerts,
					client_rehearsals: clientMus.rehearsals,
					client_raccords: clientMus.raccords,
					client_concerts: clientMus.concerts,
				};
				const adminItem = { ...adminClone[adminIndex] };
				adminItem.client_cost = sumAdminCosts(data);
				adminClone.splice(adminIndex, 1, adminItem);
				setAdministration(adminClone);
			}
		}
	}, [events, clientMus, eventDetail, administrationReset]);

	useEffect(() => {
		// jesli kzmProject=false ustaw w eventDetail wpisy kzmEvent=false
		if (!kzmProject) {
			const eventDetailCopy = structuredClone(eventDetail);
			eventDetailCopy.forEach(el => (el.kzmEvent = false));

			setEventDetail(eventDetailCopy);
		}
	}, [kzmProject]);

	useEffect(() => {
		const transportClone = structuredClone(transport);
		transp30kmMusicians(transportClone);
	}, [project30kmAway, conductor30kmAway]);

	useEffect(() => {
		if (session_data) {
			setSessionData({
				...session_data,
				currentFirst: session_data.estimateCurrent,
			})
		}
		return sessionStorage.removeItem("CALCULATION")
	},[])

	const calculation_data = {
		clientKzm: clientKzm,
		clientMus: clientMus,
		estroKzm: estroKzm,
		estroMus: estroMus,
		staff: staff,
		events: events,
		details: details,
		eventDetail: eventDetail,
		eventDuration: eventDuration,
		kzmProject: kzmProject,
		renting: renting,
		administration: administration,
		transport: transport,
		archiving: archiving,
		conductor: conductor,
		project: session_data,
	};

	const staffSum = staffValueSum(staff);
	const ratesSumCalculate = ratesCalculation(calculation_data, staffSum);

	const { clientSum, estroSum, totalSum } = sumRatesClientCost(
		calculation_data,
		staffSum,
		ratesSumCalculate
	);

	const setEventsHandler = entry => {
		// add one item detail
		setEvents(entry);
	};

	const handlePopup = async obj => {
		if (obj.type === "cancel") {
			setPopup({ show: false });
			return
		}
		if (obj.type === "ok") {
			if (session_data) {
				sessionStorage.removeItem("CALCULATION")
				navigate(session_data.pathReturn);
			}
			setPopup({ show: false });
			return;
		}
		if (obj.type === "save") {
			calculation_data["transp30km"] = transp30km;
			calculation_data["project30kmAway"] = project30kmAway;
			calculation_data["conductor30kmAway"] = conductor30kmAway;
			calculation_data["calculationNotes"] = calculationNotes;
			calculation_data["totaling"] = {
				ratesSum: ratesSumCalculate,
				staffSum: staffSum,
				clientSum: clientSum,
				estroSum: estroSum,
				grantEstroSum: estroSum - clientSum > 0
					? (estroSum - clientSum).toFixed(2)
					: '0.00',
				totalSum: totalSum,
			}
			if (calculation_data.eventDetail.length > 0) {
				const eventDetailCopy = structuredClone(calculation_data.eventDetail)
				eventDetailCopy.forEach(obj => {
					if ( (!obj.composition) || (obj.composition.length === 0) ) {
						obj['composition'] = [{value: 'Tutti', label: 'Tutti'}]
					}
				})
				calculation_data.eventDetail = eventDetailCopy
			}

			try {
				// throw new TypeError("Error net...")
				if (session_data && session_data.name) {
					calculation_data.session_data = session_data
					// calculation_data.session_data.estimateCurrent = session_data.estimateCurrent
				}

				if (params.key) {
					if (popup.saveUs) {		// zapisa kalkulacji ze wzoru innej kalkulacji
						// uaktualnienie obecnej kalk.
						const response_update = await updateCalculation(
							calculation_data,
							calculationName,
							params.key
						);
						if (response_update.popupData.variant === "error") {
							setPopup(response_update.popupData);
							return;
						}
						// zapis jako nowa kalk.
						calculation_data["calculation_name"] =
							obj.inputValue.calculation_name;

						const response_save = await saveCalculation(
							calculation_data,
							obj.inputValue.calculation_name
						);

						// jezeli estimate - utworz estimate w projekcie
						// towrzenie kopii estymacji
						// zmiana w kopii pola calculation, current=False

						// if (session_data && session_data.name === "project") {
						// 	await estimateDuplicateAPI({
						// 		calculation_id: session_data.calculationID,
						// 		estimate_from_id: session_data.estimateID,
						// 		estimateCurrent: false,
						// 	})
						// }

						setPopup(response_save.popupData);
						navigate("/sales/calculator/" + response_save.id);
						return;
					} else { // update saved calculation
						// aktualizacja zapisanej kalkulacji
						const response_save_current_calc = await updateCalculation(
							calculation_data,
							calculationName,
							params.key
						);

						if ((response_save_current_calc.events_no_free_links) && (response_save_current_calc.events_no_free_links.length > 0)) {
							const eventDetail_copy = structuredClone(eventDetail)
							response_save_current_calc.events_no_free_links.forEach(no_free_obj => {
								const eventItem = eventDetail_copy.find(el => el.key === no_free_obj.event_key)
								if (eventItem) {
									eventItem.isFree = false
									eventItem.noFreeLinks = no_free_obj.link
								}
							})
							setEventDetail(eventDetail_copy)
						}
						setPopup(response_save_current_calc.popupData);
						return;
					}
				} else {
					// zapis nowej kalk = create
					const response = await saveCalculation(
						calculation_data,
						obj.inputValue.calculation_name
					);
					// czy pomyslnie? wyswietl odpowiedni popup w zaleznosci od response
					setPopup(response.popupData);
					// if (session_data.name === "estimate") {
					// 	navigate(session_data.pathReturn)
					// } else navigate("/sales/calculator/" + response.id);
					// return;
				}
			} catch (e) {
				console.log(e);
				setPopup({
					show: true,
					variant: "error",
					title: "popups.error_save_data.title",
					message: "popups.error_save_data.message",
					okBtn: "OK",
				});
				return;
			}
			// setPopup({show: false})
		}
	};

	const saveHandler = async save_opt => {
		// otworz okno popup
		if (params.key) {
			if (save_opt === "delete") {
				setPopup({
					show: true,
					variant: "editF",
					title: t("calculator.saving_calcul_popup_title"),
					message: "calculator.saving_calcul_popup_message",
					type: "formular",
					context: {
						label: t("calculator.calculation_name.field_title"),
						calculation_name: "XXX",
					},
					leftBtn: t("common.button.cancel"),
					rightBtn: t("common.button.save"),
					saveUs: transport,
				});
			}
			if (save_opt === "save_as") {
				// zapisz obecna kalkulacje
				setPopup({
					show: true,
					variant: "editF",
					title: t("calculator.saving_calcul_popup_title"),
					message: "calculator.saving_calcul_popup_message",
					type: "formular",
					context: {
						label: t("calculator.calculation_name.field_title"),
						calculation_name: "XXX",
					},
					leftBtn: t("common.button.cancel"),
					rightBtn: t("common.button.save"),
					saveUs: transport,
				});
			} else {
				const obj = {
					type: "save",
					inputValue: { calculation_name: calculationName },
					save_opt: save_opt,
				};
				handlePopup(obj);
			}
		} else {
			setPopup({
				show: true,
				variant: "editF",
				title: t("calculator.saving_calcul_popup_title"),
				message: t("calculator.saving_calcul_popup_message"),
				type: "formular",
				context: {
					label: t("calculator.calculation_name.field_title"),
					calculation_name: "XXX",
				},
				leftBtn: t("common.button.cancel"),
				rightBtn: t("common.button.save"),
				saveUs: false,
			});
		}
	};

	const handleCalculDelete = async obj => {
		if (obj.type === "cancel") {
			setPopup({ show: false });
			return;
		}
		if (obj.type === "ok") {
			const response = await deleteCalculation(navigate, params.key);
			if (response.popupData) {
				setPopup(response.popupData);
			}
			return;
		}
		setPopup({
			show: true,
			variant: "editF",
			title: t("calculator.delete_calcul_popup_title"),
			message: t("calculator.delete_calcul_popup_message"),
			type: "delete",
			leftBtn: t("common.button.cancel"),
			okBtn: t("common.button.delete"),
			deleteStatus: true,
		});
	};

	const handleInputNameChange = e => {
		setCalculNameEditInput({ state: true, name: e });
	};

	const handleNameEdit = async () => {
		setCalculNameEditInput({ state: true, name: calculationName });
	};

	const handleNameCancel = () => {
		setCalculNameEditInput({ state: false, name: calculationName });
	};

	const handleNameSave = async () => {
		// save into db only name
		try {
			const response = await calculationEditAPI(
				{ name: calculNameEditInput.name },
				params.key
			);
			if (response.status === 204) {
				const newNameState = structuredClone(calculNameEditInput);
				setCalculNameEditInput({ ...newNameState, state: false });
				setCalculationName(calculNameEditInput.name);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleOffer = async () => {
		// save calculation
		await handlePopup({type: "save"})
		await sessionStorage.clear()
		await sessionStorage.setItem("offer_processName", "estimate")
		navigate("/projects/offer/" + params.key, {replace: true});
	};

	const changeEstimateCurrent = (val) => {
		setSessionData({
			...session_data,
			estimateCurrent: val,
		})
	}

	return (
		<>
			<DarkCard classNames='mb-3 row'>
				
				{/* Show this, if we come from Projects > In preparation > PROJECT > Edit estimate
					
					<p className="mt-0 mb-0 breadcrumbs">
						{t("menu.projects",{ ns: 'menu' })} &#9656; 
						<Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656; 
						<Link to={'/projects/in-preparation/'+session_data.projectID}>{t("breadcrumbs.project_title")} P{session_data.projectID}</Link> &#9656;
						{t("breadcrumbs.estimate_title")} E{session_data.estimateID}
					</p>

				*/}

				{/* Show this, if we are from Sales > Saved Calculations > CALCULATION
				
					<p className="mt-0 mb-0 breadcrumbs">
						{t("menu.sales",{ ns: 'menu' })} &#9656; 
						<Link to={'/sales/saved_calculations'}>{t("menu.saved_calculations",{ ns: 'menu' })} </Link> &#9656;
						{t("breadcrumbs.calculation_title")} C"tutaj ID kalkulacji"
					</p>

				*/}

				{/* Show this, if we are from Sales > Calculator
				
					<p className="mt-0 mb-0 breadcrumbs">
						{t("menu.sales",{ ns: 'menu' })} &#9656; 
						<Link to={'/sales/calculator'}>{t("menu.calculator",{ ns: 'menu' })} </Link> 
					</p>

				*/}
				
			</DarkCard>
			<DarkCard classNames='mb-3 row'>
				{/* Show this, if we come from Projects > In preparation > PROJECT > Edit estimate
				
					<TxtTitles>{t("project.estimate_name_title")}</TxtTitles>
				
				*/}

				{/* Show this, if we are from Sales > Saved Calculations > CALCULATION
				
					<TxtTitles>{t("project.calculation_name_title")}</TxtTitles>
				
				*/}

				<h1 className='mt-0'>
					{params.key ? (
						<div>
							{calculationName}
							{calculNameEditInput.state ? (
								<div>
									<Input
										name='editNameCalculation'
										value={calculNameEditInput.name}
										type={"text"}
										style={{minWidth: "250px", width: "50%"}}
										onChange={handleInputNameChange}
									/>
									<Button
										onClick={handleNameSave}
										bWidth='small'
										className='mx-2'
										style={{backgroundColor: `${Colors.green}`}}>
										{t("common.button.save")}
									</Button>
									<Button
										onClick={handleNameCancel}
										variant='square'
										bWidth='small'
										styling='bordered'
										className='mx-2'>
										{t("common.button.cancel")}
									</Button>
								</div>
							) : (
								<a className='ms-3'
								   style={{fontSize: "20px"}}
								   name='editName'
								   onClick={handleNameEdit}>
									<FontAwesomeIcon icon={faPenToSquare}/>
								</a>
							)}
						</div>
					) : (
						t("calculator.new_calculation")
					)}
				</h1>

				{/* Show this, if we come from Projects > In preparation > PROJECT > Edit estimate

						<p className="mt-3" style={{fontSize:'14px'}}>
							{t("project.project_name_title")} {session_data.projectName} <br/>
							{t("project.project_id_title")} P{session_data.projectID} <br/>
							{t("project.estimate_id_title")} E{session_data.estimateID} <br/>
						</p>

				*/}

				

				<LightCard classNames='mb-3'>
					<RedHeader
						title={t("effectif.title") + " ( " + musiciansCount(staff) + " )"}
					/>
					<Staff
						fieldNameList={staff}
						setStaff={setStaff}
						eventDetail={eventDetail}
						setEventDetail={setEventDetail}
						renting={renting}
						setRenting={setRenting}
						transport={transport}
						setTransport={setTransport}
						kzmProject={kzmProject}
						setKzmProject={setKzmProject}
						conductor={conductor}
						setConductor={setConductor}
					/>
				</LightCard>
				<LightCard classNames='mb-3'>
					<RedHeader title={t("events.title")} />
					<Events
						events={events}
						setEvents={setEventsHandler}
						eventDetail={eventDetail}
						setEventDetail={setEventDetail}
						staff={staff}
						compositionOptions={compositionOptions}
						setCompositionOptions={setCompositionOptions}
						details={details}
						setDetails={setDetails}
						kzmProject={kzmProject}
						locations={locations}
						params={params}
						conductor={conductor}
					/>
				</LightCard>
				<LightCard classNames='mb-3'>
					<Rates
						clientKzm={clientKzm}
						clientMus={clientMus}
						estroKzm={estroKzm}
						estroMus={estroMus}
						setClientKzm={setClientKzm}
						setClientMus={setClientMus}
						setEstroKzm={setEstroKzm}
						setEstroMus={setEstroMus}
						ratesCalculation={ratesSumCalculate}
					/>
				</LightCard>
				<LightCard classNames='mb-3'>
					<div className='row'>
						<div className='col-md-6'>
							<RedHeader title={t("renting.title")} />
							<RentingCosts
								cost={renting}
								setCost={setRenting}
								typeCost='renting'
								staff={staff}
								setStaff={setStaff}
							/>
						</div>
						<div className='col-md-6'>
							<RedHeader title={t("transport.title")} />
							<TransportCosts
								transp30km={transp30km}
								setTransp30km={setTransp30km}
								transport={transport}
								setTransport={setTransport}
								project30kmAway={project30kmAway}
								setProject30kmAway={setProject30kmAway}
								conductor30kmAway={conductor30kmAway}
								setConductor30kmAway={setConductor30kmAway}
								type='transport'
								staff={staff}
							/>
						</div>
					</div>
				</LightCard>
				<LightCard classNames='mb-3'>
					<div className='row'>
						<div className='col-md-6'>
							<RedHeader title={t("administration.title")} />
							<AdministrationCosts
								administration={administration}
								setAdministration={setAdministration}
								type='administration'
								administrationReset={administrationReset}
								setAdministrationReset={setAdministrationReset}
							/>
						</div>
						<div className='col-md-6'>
							<RedHeader title={t("archiving.title")} />
							<ArchivingCosts
								archiving={archiving}
								setArchiving={setArchiving}
								type='archiving'
							/>
						</div>
					</div>
				</LightCard>
				<LightCard classNames='mb-3'>
					<RedHeader title={t("common.titles.total")} />
					<div className='row px-2'>
						<div className='col-sm-3 txtCenter'>
							<p>
								<u>{t("rates.clients_costs")}</u>
							</p>
							<TotalPriceStyle>
								{clientSum > 0 ? clientSum : 0}€
							</TotalPriceStyle>
						</div>
						<div className='col-sm-3 txtCenter'>
							<p>
								<u>{t("rates.estros_costs")}</u>
							</p>
							<TotalPriceStyle>{estroSum > 0 ? estroSum : 0}€</TotalPriceStyle>
						</div>
						<div className='col-sm-3 txtCenter'>
							<p>
								<u>{t("rates.estro_grant")}</u>
							</p>
							<TotalPriceStyle>
								{parseFloat(
									estroSum - clientSum > 0 ? estroSum - clientSum : 0
								).toFixed(2)}€
							</TotalPriceStyle>
						</div>
						<div className='col-sm-3 txtCenter'>
							<p>
								<u>{t("rates.total_costs")}</u>
							</p>
							<TotalPriceStyle>{totalSum > 0 ? totalSum : 0}€</TotalPriceStyle>
						</div>
					</div>
				</LightCard>
			</DarkCard>

			{popup.show ? (
				<PopupForm
					handlePopup={popup.deleteStatus ? handleCalculDelete : handlePopup}
					variant={popup.variant}
					title={t(popup.title)}
					message={t(popup.message)}
					leftBtn={popup.leftBtn}
					rightBtn={popup.rightBtn}
					okBtn={popup.okBtn}
					context={popup.context}
				/>
			) : (
				<>
					<DarkCard classNames='mb-3 row'>
						<LightCard>
							<RedHeader title={t("common.titles.calc_notes")} />
							<textarea
								name={"calcNotes"}
								value={calculationNotes}
								onChange={e => {
									setCalculationNotes(e.target.value);
								}}
								rows={6}
								style={{ width: "100%", fontSize: "19px" }}
							/>
						</LightCard>
					</DarkCard>

					<DarkCard classNames='mb-3 row'>
						{session_data
								? !session_data.currentFirst
									? <div className="col-sm-12 col-xl-2 mb-2">
										<InputWrapperDiv>
											<TxtTitles>
												{t("project.define_estimate_as_basis_title")}
											</TxtTitles>
											<SlideButton
												type='yesNo'
												val={session_data.estimateCurrent}
												setVal={val => changeEstimateCurrent(val)}
											/>
										</InputWrapperDiv>
									</div>
									: 	<div>
											<TxtTitles>Estimate status</TxtTitles>
											<span style={{color: `${Colors.green}`, fontWeight: 'bold'}}>
												<FontAwesomeIcon icon={faCheckCircle} className="me-2"/>
												{t("project.selected_title")}
											</span>
										</div>
								: null
						}			
					</DarkCard>

					<DarkCard classNames='mb-3 row'>
						<div>
							<Button
								className='me-4'
								variant='round'
								style={{backgroundColor: `${Colors.green}`}}
								name='save'
								onClick={() => saveHandler("save")}>
								{t("common.button.save")}
							</Button>

							{params.key && (
								<>
									<Button
										className='me-4'
										variant='round'
										style={{backgroundColor: `${Colors.orange}` }}
										name='save'
										onClick={() => saveHandler("save_as")}>
										{t("common.button.save_as_new")}
									</Button>

									<Button
										className='me-4'
										variant='round'
										style={{ backgroundColor: Colors.red }}
										name='delete'
										onClick={() => handleCalculDelete({})}>
										{t("common.button.delete")}
									</Button>

									{/*<Button*/}
									{/*	className='me-4 pull-right'*/}
									{/*	variant='round'*/}
									{/*	style={{ backgroundColor: Colors.skyBlue }}*/}
									{/*	name='offer'*/}
									{/*	onClick={() => handleOffer()}>*/}
									{/*	{t("common.button.create_offer")}*/}
									{/*</Button>*/}
								</>
							)}
						</div>
					</DarkCard>
				</>
			)}
		</>
	);
}
export default Calculator;
