import {Colors} from "../components/theme";
import styled from "styled-components";

const TitleStyle = {
    marginBottom: '5px'
}

const TextStyle = {
    marginTop: '0px',
    color: `${Colors.darkGray}`,
}


const ElementStyle = styled.div `
    border-radius: 5px;
    padding: 5px 15px;
    margin: 10px 0px;
    background-color: ${Colors.lightBG};
`

const DetailsStyle = styled.div `
    div {
        margin: 3px 15px;

        &:first-child {
            margin-left: 0px
        }
        
        &:last-child {
            margin-right: 0px
        }
    }
`

const FieldStyle = styled.div `
    @media (min-width: 992px) {
    width: ${props => (props.width ? props.width : '33%')};
    }
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
`

const CityStyle = styled.div `
    @media (min-width: 992px) {
        width: 33%;
    }
`

const CountryStyle = styled.div `
    @media (min-width: 992px) {
        width: 33%;
    }
`

const NoteStyle = styled.div `
    border-top: 1px dashed ${Colors.black};
    width: 100%;
`

const NoteParagraphStyle = styled.p `
    font-size: 0.9rem;
    margin: 5px 0px 0px 0px;
`

const ModificationButton = styled.button`
    all: unset;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 500;
    font-style: normal;
    background-color: none;
    vertical-align: middle;
    border: none;
    &:hover {
        cursor: pointer;
        background-color: none;
        color: ${Colors.darkRed};
    }
`

export {
    TextStyle,
    TitleStyle,
    ElementStyle,
    DetailsStyle,
    FieldStyle,
    CityStyle,
    CountryStyle,
    NoteStyle,
    NoteParagraphStyle,
    ModificationButton,
}