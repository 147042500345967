import {Route, Routes} from "react-router-dom";
import ClientsList from "../Clients/ClientsList";
import ClientDetails from "../Clients/ClientDetails";
import ClientManager from "../Clients/ClientManager";
import FirmsList from "../Firms/FirmsList";
import FirmsDetails from "../Firms/FirmsDetails";
import FirmManager from "../Clients/FirmManager";

const ClientsRoutes = () => {
    return (<>
        <Routes >
            <Route exact path={"/clients/contacts"} element={<ClientsList />}/>
            <Route exact path={"/clients/contacts/details/:key"} element={<ClientDetails />}/>
            <Route exact path={"/clients/contacts/create"} element={<ClientManager />}/>
            <Route exact path={"/clients/contacts/:key"} element={<ClientManager />}/>
            <Route exact path={"/clients/contacts/edit/:key"} element={<ClientManager />}/>
            <Route exact path={"/clients/companies"} element={<FirmsList />}/>
            <Route exact path={"/clients/companies/details/:key"} element={<FirmsDetails />}/>
            <Route exact path={"/clients/companies/create"} element={<FirmManager />}/>
            <Route exact path={"/clients/companies/edit/:key"} element={<FirmManager />}/>
        </Routes>
    </>)
}

export default ClientsRoutes


