import {
    firmCreateAPI, firmDetailAPI,
    firmUpdateAPI,
    typesFirmListAPI
} from "../../../../../services/api/clients";
import {popupData} from "../../../../../helpers/dictionary/users";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {validate} from "../../../../sign_up/components/validations";
import PopupForm from "../../../../../components/ui/PopupForm";
import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import {Button} from "../../../../../components/ui/Button";
import FormFirm from "./FormFirm";
import { Colors } from "../../../../../components/theme";
import useLocalStorageState from "use-local-storage-state";
import {useSessionStorage} from "usehooks-ts";
import {options_in_session} from "../../../../../services/sessionData";


const field_list = {
    name: "",
    type: {label: "", value: ""},
    note: "",
    street: "",
    number: "",
    post_code: "",
    place: "",
    country: "",
}

const firmSave = async (data, id) => {
    let response
    try {
        if (id) {
            response = await firmUpdateAPI(data, id)
        } else {
            response = await firmCreateAPI(data)
        }
        if (response.status === 201 || response.status === 204) {
            return {popupData: popupData.saveOK, id: response.data.id}
        }
    } catch (e) {
        console.log("Failed to download firm list", e);
        return {popupData: popupData.saveError}
    }
}


const firmList = async (params) => {
    try {
        if (params) {
            const [firmsTypesData, firmDetailData] = await Promise.all([typesFirmListAPI(), firmDetailAPI(params)])
            return {firmsTypesData: firmsTypesData.data, firmDetailData: firmDetailData.data}
        }
        const response = await typesFirmListAPI()
        return {firmsTypesData: response.data, firmDetailData: {type: {label: "", value: 0}}}
    } catch (e) {
        console.log("Failed to download firms types list", e);
    }
}

const validationRules = {
    name: ["required"],
    type: ["required"],
    street: ["required"],
    number: ["required"],
    post_code: ["required"],
    place: ["required"],
    country: ["required"],
    note: [],
}


const FirmManager = () => {
    const [typesFirms, setTypesFirms] = useState([{label: "", value: 0}, ])
    const [form, setForm] = useState(field_list)
    const localStorageData = localStorage.getItem("formData");
    const [storageData, setStorageData] = useLocalStorageState("formData",localStorageData);
    const [settings, setSettings] = useSessionStorage("options-in-session", options_in_session);
    const [popup, setPopup] = useState({show: false});
    const params = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()

    useEffect(() => {
            const importDataState = async () => {
                const dataResult = await firmList(params.key)
                setForm(dataResult.firmDetailData)
                setTypesFirms(dataResult.firmsTypesData)
            }
            importDataState()
    }, [])

    const changeHandler = (val, fieldName) => {
        const newEntry = {
            ...form,
            [fieldName]: val,
        }
        setForm(newEntry);
    }

    const handleSave = async (event) => {
        event.preventDefault()
        const error = Object.entries(form).some(([key, value]) => {
            const test = validate(validationRules[key], value);
            if (test) {
                return true
            }
        })
        if (error) {return}

        const formData = {...form, 'type': form.type.value}
        const response = await firmSave(formData, params.key)

        if (response.popupData.variant === 'success') {
            if (storageData) {
                const clientCp = structuredClone(storageData.form)
                clientCp.firms.push({
                    firm_name: form.name,
                    firm_pk: response.id,
                    info: ''
                })
                setStorageData({
                    ...storageData,
                    "form": clientCp,
                })
            }
        }
        setPopup(response.popupData)
    }

    const cancelChandler = () => {
        if (settings && settings.pathReturn) {
            navigate(settings.pathReturn)
        } else if (!params.key) {
            navigate("/clients/companies")
        } else {
            navigate("/clients/companies/details/"+params.key)
        }
    }

    const handlePopup = () => {
        // setPopup({show: false})
        if (popup.variant === "success") {
            navigate(-1)
        }
    }

    return (<>
        {popup.show
            ? <PopupForm
                handlePopup={handlePopup}
                variant={popup.variant}
                title={t(popup.title)}
                message={t(popup.message)}
                leftBtn={popup.leftBtn}
                rightBtn={popup.rightBtn}
                okBtn={popup.okBtn}
                context={popup.context}
            />
            : <>
                <form name="client" onSubmit={handleSave}>
                <DarkCard classNames='mb-3 row'>Breadcrumbs: Clients -- Companies -- Create Company</DarkCard>
                <DarkCard classNames='mb-3 row'>
                    <div className="col-lg-6 col-xxl-4 mb-4">
                        <LightCard>
                            <RedHeader title={t("company.details")}/>
                            <FormFirm
                                form={form}
                                typesFirm={typesFirms}
                                changeHandler={changeHandler}
                                required={true}
                            />

                        </LightCard>
                    </div>

                    <div>
                        <Button
                            variant="round"
                            onClick={cancelChandler}
                            styling="bordered"
                            className={'me-3'}
                        >
                            {t("common.button.cancel")}
                        </Button>
                        <Button
                            style={{backgroundColor:`${Colors.green}`}}
                            variant="round"
                            type="submit"
                        >
                            {t("common.button.save")}
                        </Button>
                    </div>
                </DarkCard>
                </form>
            </>}
    </>)
}

export default FirmManager