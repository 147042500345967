import React from "react";
import {useTranslation} from "react-i18next";
import {Text,View,} from "@react-pdf/renderer";
import {styles} from "./Styles/Styles";

const selectedEntry = (data) => {
    return (<>
        <Text>
            {data.number} {data.street}
        </Text>
        <Text>
            {data.post_code} {data.place}
        </Text>
        <Text>
            {data.country}
        </Text>
    </>)
}

const Recipient = ({ client, firm, docDate }) => {
    const { t } = useTranslation();
    let recipient_name = undefined
    let recipient_address = undefined

    if ((firm) && (firm.value > 0)) {
        recipient_name = (<>
            <Text>
                {firm.label}
            </Text>
            <Text>
                {client.label}
            </Text>
        </>)
        recipient_address = selectedEntry(firm.details.address)
    } else {
        recipient_name = (
        <Text>
            {client.first_name} {client.last_name}
        </Text>
        )
        recipient_address = selectedEntry(client)
    }

    return (
        <View style={styles.titleContainer}>
            <View style={{width: '50%'}}>
                <Text style={styles.text}>
                    {t("offer.pdf_document.texts.lu_country")} {docDate}
                </Text>
            </View>
            <View style={{width: '50%'}}>
                <View style={styles.rightContent}>
                    <Text style={styles.redTitle}>
                        {t("offer.pdf_document.texts.recipient")}
                    </Text>
                </View>
                <View style={styles.rightContent}>
                    {recipient_name}
                    {recipient_address}
                </View>
            </View>
        </View>
    );
}

export default Recipient;
