import {project} from "./endpoints";
import axiosClient from "./api";

const axiosClientJSON = axiosClient()
const axiosClientForm = axiosClient('multipart/form-data')

const estimateAPI = async (data) => {
    return await axiosClientForm.post(project.estimate, data);
};

const projectListAPI = async (data) => {
    return await axiosClientJSON.get(project.shortData);
}

const estimateDuplicateAPI = async (data) => {
    return await axiosClientForm.post(project.duplicateEstimate, data);
};

export {
    estimateAPI,
    estimateDuplicateAPI,
    projectListAPI,
}