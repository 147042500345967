import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DarkCard from "../../../../../components/card/DarkCard";
import {clientDetailAPI} from "../../../../../services/api/clients";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import {InputWrapperDiv} from "../../../../../components/ui/Input";
import { EditButton } from "../../../../../components/ui/EditButton";
import Addresses from "../../../../../components/ui/Addresses";
import { Colors } from "../../../../../components/theme";


const ClientDetails = () => {
    const params = useParams()
    const { t } = useTranslation()
    const [client, setClient] = useState({})
    const return_path = "/"+params['*']

    useEffect(() => {
        const getDetail = async () => {
            try {
                const details = await clientDetailAPI(params.key)
                setClient(details.data)
            } catch (e) {
                console.info("Failed to download details client...", e)
                return
            }
        }
        getDetail()
    }, [])

    const handleSetSession = () => {
        sessionStorage.setItem(
            "options-in-session", JSON.stringify({pathReturn: return_path})
        )
    }

    return (<>
        <DarkCard classNames='mb-3 row'>
            Breadcrumbs: Clients details
        </DarkCard>
        <DarkCard classNames='mb-3 row'>
            <h1>
                {client.first_name} {client.last_name}
                <EditButton gotoName={"/clients/contacts/edit/"+params.key} handleClickProps={handleSetSession} />
            </h1>
            <div className="row">
                <div className="col-lg-6 col-xl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("clients.details_title")}/>
                        <InputWrapperDiv addClass="px-2">
                            <p className="mb-2" style={{fontWeight:'bold'}}>
                                {client.first_name} <span style={{textTransform: 'uppercase'}}>{client.last_name}</span>
                            </p>
                               <Addresses obj={client} />
                            <p className="mt-3">
                                {t("profile.tel")+': '} <a href={"tel:" + client.phoneCountry + client.telephone}>{client.phoneCountry} {client.telephone}</a> <br/>
                                {t("profile.mail")+': '} <a href={"mailto:"+ client.email}>{client.email}</a>
                            </p>
                            {
                                client.note
                                    ? <p className="mt-1">
                                        <b style={{color:`${Colors.darkRed}`}}>{t("profile.note")}:</b> <br/>
                                        {client.note}
                                      </p>
                                    : null
                            }
                        </InputWrapperDiv>
                    </LightCard>
                </div>
                
                    <div className="col-lg-6 col-xl-4 mb-4">
                        <LightCard>
                            <RedHeader title={t("profile.companies")}/>
                            <InputWrapperDiv addClass="px-2">
                                {
                                    client.firms && client.firms.length >0 ? client.firms.map(el =>
                                            <div key={el.pk} className="mb-4" style={{borderBottom:'1px solid #000'}}>
                                                <p className="pb-0 mb-0" style={{fontWeight:'bold'}}>
                                                    {el.firm_name ? el.firm_name : null}
                                                </p>
                                                <p className="mt-0">{el.firm_type.name}</p>
                                                
                                                <Addresses obj={el.firm_address} />
                                                {el.info
                                                    ? <p className="pb-0">
                                                        <b style={{color:`${Colors.darkRed}`}}> {t("profile.responsibility")}: </b> <br/>
                                                        {el.info }
                                                      </p>
                                                    : null
                                                }
                                            </div>
                                        ): <i>{t("profile.no_company")}</i>
                                }
                            </InputWrapperDiv>
                        </LightCard>
                    </div>
                
            </div>
        </ DarkCard>
    </>)
}

export default ClientDetails

