import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";
import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import DatePicker from "react-datepicker";
import {fr} from "date-fns/locale";
import Select from "react-select";
import {languagesList} from "../../../../../../helpers/dictionary/users";
import select_styles from "../../../../../../layouts/elements";
import SlideButton from "../../../../../../components/ui/SlideButton";

const OtherEstimateData = ({doc, dispatch}) => {
    const { t } = useTranslation()
    const {program, comment, calculation, estimateDate, language, showEstroGrant} = doc

     return (
        <>
        <div className="col-lg-6 col-xl-4 mb-4">
            <RedHeader title={t("offer.create_offer.estimate_details_title")}/>
            <div className="row">
                <div className="col-lg-6">
                    <InputWrapperDiv>
                        {t("offer.create_offer.document_date")}
                        <DatePicker
                            name='event_date'
                            selected={estimateDate}
                            onChange={val => dispatch({
                                type: "SET_ESTIMATE_DATE",
                                estimateDate: val,
                                key: "estimateDate"
                            })}
                            dateFormat='dd-MM-yyyy'
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode='select'
                            locale={fr}
                            wrapperClassName='dataInputWrapper'
                            className='dataInput'
                        />
                    </InputWrapperDiv>
                </div>

                <div className="col-lg-6">
                    <InputWrapperDiv>
                        {t("offer.create_offer.select_language")}
                        <Select
                            name='firms-list'
                            options={languagesList}
                            value={language}
                            onChange={val => dispatch({
                                type: "SET_LANGUAGE",
                                language: val,
                                key: "language"
                            })}
                            styles={select_styles}
                            unstyled
                            required="required"
                        />
                    </InputWrapperDiv>
                </div>

                <div className="col-lg-6 mt-3">
                    <p className="my-0 mb-1">
                        {t("offer.create_offer.show_estro_grant")} &nbsp;
                        <i>({calculation.totaling.grantEstroSum} €)</i>
                    </p>
                    <div className="col-sm-12 col-xl-2 mb-2">
                        <SlideButton
                            type='yesNo'
                            val={showEstroGrant}
                            setVal={val => dispatch({
                                type: "SET_SHOW_ESTRO_GRANT",
                                showEstroGrant: val,
                                key: "showEstroGrant"
                            })}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-6 col-xl-4 mb-4">

            <RedHeader title={t("offer.create_offer.project_program_title")}/>
            <div className="row">
                <div className="col">
                    <textarea
                        name={"estimate_program"}
                        value={program}
                        onChange={(e) => dispatch({
                            type: "SET_PROGRAM",
                            program: e.target.value,
                            key: "program"
                        })}
                        rows={6}
                        style={{width: "100%", fontSize:"19px"}
                        }
                    />
                </div>
            </div>
        </div>
        <div className="col-lg-8 col-xl-8 mb-4">
            <RedHeader title={t("offer.create_offer.additional_comment_title")}/>
            <div className="row">
                <div className="col">
                    <textarea
                        name={"comment"}
                        value={comment}
                        onChange={(e) => dispatch({type: "SET_COMMENT", comment: e.target.value, key: "comment"})}
                        rows={6}
                        style={{width: "100%", fontSize:"19px"}}
                    />
                </div>
            </div>
        </div>
        </>
    )
}

export default OtherEstimateData