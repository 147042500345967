import DarkCard from "../../../../../components/card/DarkCard";
import styled from "styled-components";
import ContactListElement from "../../../../../components/ui/ContactListElement";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { useTranslation } from "react-i18next";
import StatusCircle from "../../../../../components/ui/StatusCircle";
import { Colors } from "../../../../../components/theme.js";
import SlideButton from "../../../../../components/ui/SlideButton";


const FilterStyle = styled.div `
    background-color: ${Colors.lightBG};
    border-radius: 5px;
    padding: 8px 13px;
`

function MusiciansList () {
    const { t } = useTranslation();

    return (
        <>
            Musicians -- Contacts Page    
            
            <DarkCard>
                <div className="d-flex flex-row flex-wrap flex-xl-nowrap">
                    <FilterStyle className="mb-3 mb-xl-0 ms-3 ms-xl-0 me-3">
                        <p className="mt-0 mb-1"><b>{t("common.data_filters.musician.status")}:</b></p>
                        <ul className="noListStyle m-0">
                            <li className="mb-1"><StatusCircle status={'waiting'} />{t("common.data_filters.status.pending")}</li>
                            <li><StatusCircle status={'approved'} />{t("common.data_filters.status.approved")}</li>
                        </ul>
                    </FilterStyle>
                    <FilterStyle className="mb-3 mb-xl-0 mx-3">
                        <p className="mt-0 mb-1"><b>{t("common.data_filters.musician.group")}:</b></p>
                        <ul className="noListStyle m-0">
                            <li className="mb-1">{t("common.data_filters.musician_groups.harmonie")}</li>
                            <li>{t("common.data_filters.musician_groups.cordes")}</li>
                        </ul>
                    </FilterStyle>
                    <FilterStyle className="mb-3 mb-xl-0 mx-3">
                        <p className="mt-0 mb-1"><b>{t("common.data_filters.musician.category")}:</b></p>
                        <ul className="noListStyle m-0">
                            <li className="mb-1">
                                <span className="regularMusician pe-1">{t("common.data_filters.musician_categories.regular")}</span>
                            </li>
                            <li>
                                <span className="additionalMusician pe-1">{t("common.data_filters.musician_categories.renfort")}</span>
                            </li>
                        </ul>
                    </FilterStyle>
                    <FilterStyle className="mb-3 mb-xl-0 mx-3">
                        <p className="mt-0 mb-1"><b>{t("common.data_filters.musician.first_name_sort")}:</b></p>
                        <ul className="noListStyle m-0">
                            <li className="mb-1">A-Z</li>
                            <li>Z-A</li>
                        </ul>
                    </FilterStyle>
                    <FilterStyle className="mb-3 mb-xl-0 mx-3">
                        <p className="mt-0 mb-1"><b>{t("common.data_filters.musician.comments")}:</b></p>
                        <SlideButton/>
                    </FilterStyle>
                    <FilterStyle className="mb-3 mb-xl-0 ms-3 flex-fill">
                        <p className="mt-0 mb-1"><b>{t("common.data_filters.musician.search")}:</b></p>
                        <input style={{width:'100%'}}></input>
                    </FilterStyle>
                </div>

                {/* This div and the content inside is to delete */}
                <div>
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'     
                        linkto='/musicians/contacts/profile'       
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='1'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        comment='This is a comment of this specific musician'
                        musicianClass='regularMusician'            
                        status='approved'            
                    />
                    <ContactListElement 
                        positionNr='2'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'              
                        musicianClass='additionalMusician'            
                    />
                    <ContactListElement 
                        positionNr='3'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        comment='This is a comment of this specific musician'     
                        musicianClass='additionalMusician'
                        status='waiting'            
                    />
                    <ContactListElement 
                        positionNr='4'
                        fName='Charlie'                
                        lName='Labroche'
                        phone='+352691719213'
                        mail='mail@estro.lu'              
                        instruments='Alto'         
                        musicianClass='regularMusician'
                        status='approved'            
                    />
                </div>
                
            </DarkCard>
        </>
)
};
export default MusiciansList;
