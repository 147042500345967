import {useTranslation} from "react-i18next";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import Select from "react-select";
import select_styles from "../../../../../../layouts/elements";
import React from "react";
import FirmDetails from "./FirmDetails";

const FirmsClientSelect = ({firm, firmList, handleNewEdit, dispatch}) => {
    const { t } = useTranslation()

    console.info("firm", firm, firmList)

    return (
        <div className="col-lg-6 col-xl-4 mb-4">
            <RedHeader title={t("offer.create_offer.company_data_title")}/>
            <div className="row pb-2">
                <InputWrapperDiv>
                    <div className='customSelectParent'>
                        <br />
                        <div className="addGroup pull-right">
                            <span className="ps-2"></span>
                        </div>

                        <Select
                            name='firms-list'
                            options={firmList}
                            value={firm}
                            onChange={e => dispatch({type: "SELECT_FIRM", firm: e, key: "firm"})}
                            styles={select_styles}
                            unstyled
                            className="mt-3"
                        />
                    </div>
                </InputWrapperDiv>
            </div>
            {firm && firm.hasOwnProperty('details')
            ? <FirmDetails firm={firm} handleNewEdit={handleNewEdit}/>
            : null
            }
        </div>
    )
}

export default FirmsClientSelect
