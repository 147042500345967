// Used Tutorial for understanding  https://www.youtube.com/watch?v=KvctnEZSvtM
// Conditional Styling: https://medium.com/@nehrwein/conditional-styling-with-styled-components-in-react-c26bd9f0db88
// Multiple prop options: https://stackoverflow.com/questions/56047659/multiple-props-options-for-styled-components

import styled from "styled-components"
import { Colors } from "../theme";

const handleWidth = bWidth => {
    switch (bWidth) {
        case "full":
          return "100%";
        case "half":
          return "50%";
        case "normal":
          return "auto";
        case "small":
          return "100px";
        default:
          return "auto";
    }
}

const handleVariant = variant => {
    switch (variant) {
        case "square":
          return "0px";
        case "round":
          return "25px";
        default:
          return "0px";
    }
}

const PropouseButtons = {
    'TITLE': 'title',
    'NEXT': 'next',
    'CONFIRM': 'confirm'
};

const getSpecificDesign = (current, itemId, proposueButton) => {
    //"add_reh" | "add_racc" | "add_concert"
    if (proposueButton === 'rehearsals') {
        return `background-color: ${Colors.rehearsal};
                cursor: pointer;
                &:hover {
                    background-color: ${Colors.rehearsal_dark};
                };
        `
    }

    if (proposueButton === 'raccords') {
        return `background-color: ${Colors.raccord};
                cursor: pointer;
                &:hover {
                    background-color: ${Colors.raccord_dark};
                };
        `
    }
    
    if (proposueButton === 'concerts') {
        return `background-color: ${Colors.concert};
                cursor: pointer;
                &:hover {
                    background-color: ${Colors.concert_dark};
                };
        `
    }

    if (itemId === current) {
        return `background-color: ${Colors.darkRed};
                cursor: pointer;
                &:hover {
                    background-color: ${Colors.red};
                };
        `
    }
    if (itemId < current) {
        return `background-color: ${Colors.red};
                cursor: pointer;
                &:hover {
                    background-color: ${Colors.darkRed};
                };
        `
    }
    if (itemId > current) {
        return `background-color: ${Colors.mediumGray};
                cursor: default;
        `
    }    
};

const ButtonComponent = styled.button`
    padding: 10px 15px;
    font-size: 1rem;
    vertical-align: middle;
    text-align: center;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 900;
    font-style: normal;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    min-width: 95px;
    width: ${({ bWidth }) => handleWidth(bWidth)};
    border-radius: ${({ variant }) => handleVariant(variant)};
    margin: 10px 0px;

    ${props => {
        if (props.disabled) {
            return `
                cursor: default;
                color: ${Colors.mediumGray};
                background-color: ${Colors.darkGray};
                border: none;
            `
        } else {
            if (props.styling === 'bordered') {
                return `
                    cursor: pointer;
                    background-color: rgba(0,0,0,0);
                    border: 2px solid ${Colors.darkGray};
                    color: ${Colors.darkGray};
                    &:hover {
                        background-color: ${Colors.darkGray};
                        color: ${Colors.white};
                    };
                `
            } else if (props.proposueButton !== undefined && props.proposueButton !== null) {
                return `
                    color: ${Colors.white};
                    border: none;
                    ${getSpecificDesign(props.clicked,props.itemId,props.proposueButton)}
                `
            } else {
                return `
                    color: ${Colors.white};
                    border: none;
                    background-color: ${Colors.red};
                    cursor: pointer;
                    &:hover {
                        background-color: ${Colors.darkRed};
                    };
                `
            }
        }
    }
}`;

/**
 @param {{
    type: "button *" | "submit" | "reset";
    variant: "square *" | "round";
    bWidth: "normal *" | "full" | "half";
    styling: "plain *" | "bordered";
    color: "green" | "orange";
    proposueButton: "title *" | "next" | "confirm" | "rehearsals" | "raccords" | "concerts";
 }} props
*/

const Button = ({
                    proposueButton,
                    type,
                    variant,
                    bWidth,
                    styling,
                    className,
                    itemId,
                    onClick,
                    children,
                    disabled,
                    clicked,
                    style,
                    name
}) => {
    return (
        <ButtonComponent
            type={type ? type :'button'}
            variant={variant}
            bWidth={bWidth}
            styling={styling}
            className={className}
            itemId={itemId}
            clicked={clicked}
            onClick={onClick}
            disabled={disabled}
            proposueButton={proposueButton}
            style={style}
            name={name}
        >   
            {children}
        </ButtonComponent>
    )
};

export {Button, PropouseButtons};