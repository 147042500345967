import Axios from 'axios'
import useAxios, {configure} from "axios-hooks";
import TokenService from "../services/token.service";
import {useEffect, useState} from "react";
let tokenTimer;

const setTokenTimestamp = () => {
    const TOKEN_TTL = Number(localStorage.getItem("token_ttl")) || 0
    // const expiry = localStorage.getItem("expiry");
    // if (Date.now() > expiry) { // nie wazny
    // 	TokenService.clearLocalStorage();
    // 	window.location.replace("/login");
    // 	return
    // }
    localStorage.setItem("expiry", Date.now() + TOKEN_TTL)

    if (typeof tokenTimer === "number") {
        clearTimeout(tokenTimer)
    }

    tokenTimer = setTimeout(() => {
        TokenService.clearLocalStorage();
        window.location.replace("/login");
    }, TOKEN_TTL)
};

const axiosInstanceCreate = (contentType) => {
    // contentType = 'multipart/form-data'
    const axiosInstance = Axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
            "Content-Type": contentType,
        },
    });

    axiosInstance.interceptors.request.use(requestConfig => {
        if (requestConfig?.headers) {
            const authToken = TokenService.getLocalAccessToken();

            if (authToken) {
                setTokenTimestamp()
                requestConfig.headers["Authorization"] = `Token ${authToken}`;
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
        }
        return requestConfig;
    });

    axiosInstance.interceptors.response.use((response) => response,
        error => {
            const { response } = error;
            if (error.code === "ERR_NETWORK") {
                window.location.replace('/network-error')
            }
            else {
                if (response.status === 400) {
                    return response
                } else if (response.status === 401 ||
                    response.data.errors === "authenticationCredentialsWereNotProvided." ||
                    response.data.errors === "Invalid token."
                ) {
                    TokenService.clearLocalStorage();
                    window.location.replace("/login");

                } else if (response.status === 404) {
                    console.log("error 404: ", response)
                    response.data.status = 404
                    return response
                } else {
                    console.log(" useEndpoint ---OTHER ERROR >>>>>>>: ", response)
                    window.location.replace('/network-error')
                };
            }
        }
    );

    axiosInstance.defaults.timeout = 30 * 1000;

    configure({axios: axiosInstance, cache: false})
}

function useEndpoint(url, payload, method="GET", contentType="application/json") {
    // const [data, setData] = useState(null);
    // const [error, setError] = useState("");
    // const [loaded, setLoaded] = useState(false);

    axiosInstanceCreate(contentType)

    // const [{data, loading, error}]
    return useAxios(url)
}

// function useEndpointPayload(url, payload, method="GET", contentType="") {
//     // const [data, setData] = useState(null);
//     // const [error, setError] = useState("");
//     // const [loaded, setLoaded] = useState(false);
//
//     axiosInstanceCreate(contentType)
//
//     // const [{data, loading, error}]
//     return useAxios(url)
// }

export { useEndpoint };