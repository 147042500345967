import axiosClient from "./api"
import {calculator, project} from "./endpoints"

const axiosClientJSON = axiosClient()
// const axiosClientForm = axiosClient('multipart/form-data')

const calculationSaveAPI = async (data) => {
    return await axiosClientJSON.post(calculator.calculations, data);
}

const calculationSaveItemAPI = async (data, pk) => {
    return await axiosClientJSON.put(calculator.calculationDetail(pk), data);
}

const calculationsListAPI = async () => {
    return await axiosClientJSON.get(calculator.calculations);
}

const calculationDetailAPI = async (id) => {
    return await axiosClientJSON.get(calculator.calculationDetail(id));
}

const calculationDeleteAPI = async (id) => {
    return await axiosClientJSON.delete(calculator.calculationDelete(id));
}

const calculationEditAPI = async (data, id) => {
    return await axiosClientJSON.patch(calculator.calculationEdit(id), data);
}

const isFreeEventDateAPI = async (data) => {
    return await axiosClientJSON.post(calculator.idFreeEventDate, data);
}

export {
    calculationSaveAPI,
    calculationSaveItemAPI,
    calculationsListAPI,
    calculationDetailAPI,
    calculationDeleteAPI,
    calculationEditAPI,
    isFreeEventDateAPI,
}