import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Status() {
    const {t} = useTranslation()

    return (<>
        <RedHeader title={t('project.progress_title')}/>
    </>)
}