import DarkCard from "../../../../../components/card/DarkCard";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import { Input,InputWrapperDiv } from "../../../../../components/ui/Input";
import {Link, useNavigate, useParams} from "react-router-dom";
import LordIcon from "../../../../../components/ui/LordIcon";
import { Colors } from "../../../../../components/theme";
import { useTranslation } from "react-i18next";
import LocationContactPerson from "./LocationContactPerson";
import { Button } from "../../../../../components/ui/Button";
import React, {useEffect, useState} from "react";
import {
    locationCreateAPI, locationDetailAPI,
    locationUpdateAPI
} from "../../../../../services/api/locations";
import { v4 as uuidv4 } from "uuid";
import PopupMessage from "../../../../../components/ui/PopupMessage";
import Select from "react-select";
import {getCountriesListAPI} from "../../../../../services/api/user";

const select_styles = {
    container: (state) => ({
        position: 'relative',
        margin: '5px 0px',
        backgroundColor: 'white',
        padding: '2.5px 0px',
        border: state.isFocused ? ('1px solid ' + Colors.darkRed) : ('1px solid ' + Colors.darkGray),
        "&:hover": {
            borderColor: Colors.darkRed,
        },
        "div[id$='listbox']": {
            position:'relative'
        }
    }),
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        padding: '4px 10px',
    }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        color: Colors.darkGray,
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        padding: state.isDisabled ? '5px 5px' : '5px 15px',
        "&:hover": {
            borderLeft: state.isDisabled ? '' : '10px solid'+Colors.darkRed,
            paddingLeft: state.isDisabled ? '5px' : '5px',
            backgroundColor: state.isDisabled ? '' : Colors.lightBG,
            cursor: state.isDisabled ? '' :'pointer'
        },
        color: state.isDisabled ? Colors.lightGray : Colors.darkGray,
        borderTop: '1px solid'+Colors.lightGray
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: Colors.red,
        color: Colors.white,
        margin: '5px 10px 5px 0px',
        "&:hover": {
            //backgroundColor: Colors.darkRed,
            cursor: 'pointer'
        },
    }),
    multiValueLabel: (baseStyles) => ({
        padding: '2px 5px',
    }),
    multiValueRemove: (baseStyles) => ({
        ...baseStyles,
        padding: '2px 5px',
        backgroundColor: Colors.darkRed,
        "&:hover": {
            backgroundColor: Colors.darkGray,
        },
    })
};

const locationInit = {
    name: "",
    number: "",
    street: "",
    post_code: "",
    city: "",
    country: "",
    toilet: "",
    parking: "",
    parking_details: "",
    note: "",
    map_url: ""
}

const contactPersonsInit = {
    id: 0,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    responsible_for: "",
    note: ""
}

const returnPath = (key) => `/locations/location/${key}`


function LocationAddEdit () {
    const { t } = useTranslation();
    const tp_options = [
        {value: 'yes', label: t('common.boolean.yes')},
        {value: 'no', label: t('common.boolean.no')},
        {value: 'don\'t know', label: t('common.boolean.dKnow')},
    ]
    const [location, setLocation] = useState(locationInit)
    const [contactPersons, setContactPerson] = useState([])
    const navigate = useNavigate()
    const params = useParams()
    const popupInit = {show: false, title: '', variant: 'error', message: ''}
    const [popup, setPopup] = useState(popupInit);
    const [countries, setCountries] = useState([])

    useEffect(() => {
        const getCountries = async () => {
            try {
                const resp_list = await getCountriesListAPI()
                const data = resp_list.data.qs.map(el => {
                    return {
                        value: el.name,
                        label: t('common.countries.'+el.name),
                        pk: el.pk,
                    }
                })
                setCountries(data)
            } catch (ex) {
                console.log("Failed to download locations list.", ex);
            }
        }
        getCountries()
    }, [])

    useEffect(() => {
        const getLocationItem = async () => {
            try {
                const response = await locationDetailAPI(params.key)
                setContactPerson(response.data.contact_persons)
                setLocation(response.data)
            } catch (e) {
                console.log("Failed to download location details data")
            }
        }
        if (params.key) {
            getLocationItem()
        }

    }, [params.key])

    const handlePopup = (obj) => {
        if (popup.variant === 'success') {
            params.key ? navigate(returnPath(params.key)) : navigate('/locations')
        }
        setPopup(popupInit)
    }

    const onChangeHandler = (e, attrName, type, key) => {
        if (type === 'persons') {
            const newEntry = structuredClone(contactPersons)
            const indexItem = newEntry.findIndex(el => el.key == key)
            newEntry[indexItem][attrName] = e
            setContactPerson(newEntry)
        } else {
            const newEntry = {
                ...location,
                [attrName]: e
            }
            setLocation(newEntry)
        }
    }

    const addMoreChandler = () => {
        const cpInit = {...contactPersonsInit}
        cpInit.key = uuidv4()
        const newEntry = [...contactPersons, cpInit]
        setContactPerson(newEntry)
    }

    const saveChandler = async () => {
        const locationSaveAPI = async (data) => {
            try {
                let response = undefined
                if (params.key) {
                    response = await locationUpdateAPI(data, params.key)
                } else {
                    response = await locationCreateAPI(data)
                }
                // setPopup(true)
                if ((response.status === 201) || (response.status === 200)) {
                    setPopup({show: true, title: t("popups.save_data.title"), variant: 'success', message: t("popups.save_data.location_details")})
                } else {
                    setPopup({show: true, title: t("popups.error_save_data.title"), variant: 'error', message: t("popups.error_save_data.location_details")})
                }
            } catch (e) {
                setPopup({show: true, title: t("popups.error_save_data.title"), variant: 'error', message: t("popups.error_save_data.location_details")})
            }
        }

        if (location.name.length > 0) {
            const data = {
                ...location,
                ['contact_persons']: contactPersons
            }
            await locationSaveAPI(data)
        }
    }

    const cancelChandler = () => {
        params.key ? navigate(returnPath(params.key)) : navigate('/locations')
    }

    return (<>
        {popup.show
            ? <PopupMessage
                setPopup={handlePopup}
                variant={popup.variant}
                title={popup.title}
                message={popup.message}
                // leftBtn={t("common.boolean.no")}
                okBtn={t("common.button.ok")}
            />
            : <>
            <DarkCard classNames='mb-3 row'>
                Breadcrumbs: Locations list / Add new location
            </DarkCard>

            <DarkCard classNames='mb-3 row'>
                <div className="col-lg-6 col-xxl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("location.address.title")}/>

                        <InputWrapperDiv>
                            {t("location.name")}
                            <Input
                                value={location.name}
                                onChange={e => onChangeHandler(e, 'name')}
                                name='name'
                            />
                        </InputWrapperDiv>

                        <div className="row mt-2">
                            <div className="col-xs-12 col-6">
                                <InputWrapperDiv>
                                    {t("location.address.street")}
                                    <Input
                                        value={location.street}
                                        onChange={e => onChangeHandler(e, 'street')}
                                        name='street'
                                    />
                                </InputWrapperDiv>
                                <InputWrapperDiv className="mt-2">
                                    {t("location.address.postcode")}
                                    <Input
                                        value={location.post_code}
                                        onChange={e => onChangeHandler(e, 'post_code')}
                                        name='post_code'
                                    />
                                </InputWrapperDiv>
                            </div>
                            <div className="col-xs-12 col-6">
                                <InputWrapperDiv>
                                    {t("location.address.number")}
                                    <Input
                                        value={location.number}
                                        onChange={e => onChangeHandler(e, 'number')}
                                        name='number'
                                    />
                                </InputWrapperDiv>
                                <InputWrapperDiv className="mt-2">
                                    {t("location.address.city")}
                                    <Input
                                        value={location.city}
                                        onChange={e => onChangeHandler(e, 'city')}
                                        name='city'
                                    />
                                </InputWrapperDiv>
                            </div>
                        </div>

                        <InputWrapperDiv className="mt-2">
                            {t("location.address.country")}
                            <Select
                                options={countries}
                                value={countries.find(el => el.pk === location.country)}
                                onChange={e => onChangeHandler(e.pk, 'country')}
                                name='country'
                                styles={select_styles}
                                unstyled
                            />
                        </InputWrapperDiv>
                        <InputWrapperDiv className="mt-2">
                            {t("location.address.google_link")}
                            <Input
                                value={location.map_url}
                                onChange={e => onChangeHandler(e.toString(), 'map_url')}
                                name='map_url'
                            />
                        </InputWrapperDiv>
                    </LightCard>
                </div>

                <div className="col-lg-6 col-xxl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("location.details.title")}/>

                        <div className="row mt-2">
                            <div className="col-xs-12 col-6">
                                <InputWrapperDiv>
                                    {t("location.details.toilet")}
                                    <Select
                                        name={'toilet'}
                                        options={tp_options}
                                        placeholder={t("Choose")+ " *"}
                                        value={tp_options.filter(obj => obj.value === location.toilet)}
                                        onChange={e => onChangeHandler(e.value, 'toilet')}
                                        styles={select_styles}
                                        unstyled
                                    />
                                </InputWrapperDiv>
                            </div>
                            <div className="col-xs-12 col-6">
                                <InputWrapperDiv>
                                    {t("location.details.parking")}
                                    <Select
                                        name={'parking'}
                                        options={tp_options}
                                        placeholder={t("Choose")+ " *"}
                                        value={tp_options.filter(obj => obj.value === location.parking)}
                                        onChange={e => onChangeHandler(e.value, 'parking')}
                                        styles={select_styles}
                                        unstyled
                                    />
                                </InputWrapperDiv>
                            </div>
                        </div>

                        <InputWrapperDiv className='mt-2'>
                            {t("location.details.parking_details")}
                            <Input
                                value={location.parking_details}
                                onChange={e => onChangeHandler(e, 'parking_details')}
                                name='parking_details'
                            />
                        </InputWrapperDiv>

                        <InputWrapperDiv className='mt-2'>
                            {t("location.details.location_note")}
                            <Input
                                value={location.note}
                                onChange={e => onChangeHandler(e, 'note')}
                                name='note'
                            />
                        </InputWrapperDiv>
                    </LightCard>
                </div>

                <div className="col-lg-6 col-xxl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("location.contact_person.title")}/>
                        
                        {contactPersons.map(person =>
                            <div key={person.key}>
                                <LocationContactPerson
                                    person={person}
                                    onChangeHandler={onChangeHandler}
                                />
                            </div>
                        )}

                        <div>
                            <Link className="addGroup pt-1">
                                <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup' changeActive={addMoreChandler}/>
                                <span className="ps-2" onClick={addMoreChandler}>{t("common.button.add_person")}</span>
                            </Link>
                        </div>
                    </LightCard>
                </div>
                <div>
                    <Button
                        variant="round"
                        onClick={cancelChandler}
                        styling="bordered"
                        className={'me-3'}
                    >
                        {t("common.button.cancel")}
                    </Button>

                    <Button
                        variant="round"
                        style={{backgroundColor:`${Colors.green}`}}
                        onClick={saveChandler}
                    >
                        {t("common.button.save")}
                    </Button>

                </div>
            </DarkCard>
            </>
        }
    </>
)}
export default LocationAddEdit;