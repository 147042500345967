import {Text, View} from "@react-pdf/renderer";
import {styles, stylesTotal} from "./Styles/Styles";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const OtherDataPDF = ({note, showGrant, totaling}) => {
    const {t} = useTranslation()

    return(<>
        
        {showGrant
            ? <View style={{marginHorizontal: 30}}>
                <Text style={stylesTotal.service}>
                    {t('pdf_document.texts.project_costs', {estroSum:`${totaling.estroSum}`, grantEstro:`${totaling.grantEstroSum}`})}
                </Text>
              </View>
            : null
        }

        {note
            ? <View style={{marginHorizontal: 30}}>
                <Text style={styles.documentRef}>
                    {t("pdf_document.texts.additional_infos_title")}
                </Text>
                <Text style={styles.text}>
                    {note}
                </Text>
              </View>
            : null

        }
    </>)
}

export default OtherDataPDF

