import {useTranslation} from "react-i18next";
import WhiteCard from "../../../../../../components/card/WhiteCard";
import {split} from "lodash";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const TxtTitles = styled.span`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0;
    margin: 0;
    color: ${Colors.darkRed};
`;

export default function FeedDetails({data}) {
    const {t} = useTranslation()

    const removeHandler = (id) => {
        console.log("IDDDD", id)
    }

    console.log("attachments", data)

    return (<>
        {data.map((box, index1) =>
                <div key={"boxDetails_"+index1} className="mb-3">

                    <WhiteCard>
                        <div className="row">
                            <div className="col-6">
                                <p className="m-0"><b>{box.user}</b></p>
                            </div>
                            <div className="col-6">
                                <p className="m-0" style={{textAlign:'right'}}><b>{box.created_time}</b></p>
                            </div>

                            <div className="col-12">
                                <p className="mt-2 mb-0">{box.note}</p>
                            </div>

                            {box.attachments.length > 0 && (
                                <div className="col-12 mt-2">
                                    <TxtTitles>
                                        {t("project.files_title")}
                                    </TxtTitles>

                                    <p className="mt-0 mb-0 py-2" style={{borderTop:'1px solid #e8e5e4'}}>
                                        {box.attachments.map((attachment, index2) => (
                                            <span key={"boxAttach_" + index2} className="me-3">
                                                <a href={`${process.env.REACT_APP_API_HOST}/${attachment.file}`}
                                                   target="_blank">
                                                    <span
                                                        style={{color: '#b41f23'}}>&#x2733;</span> {split(attachment.name, "\/")[1]}
                                                </a>
                                                <a className="ms-3" style={{fontSize:'20px'}}
                                                    name="editName"
                                                    onClick={e => removeHandler(attachment.id, "remove-signature")}>
                                                    <FontAwesomeIcon icon={faCircleXmark}/>
                                                </a>
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            )}
                        </div>
                    </WhiteCard>
                    
                    
                </div>
        )}

    </>)
}