import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {styles} from "./Styles/Styles";
import {useTranslation} from "react-i18next";

const Intro = ({ project, estimateID }) => {
        const { t } = useTranslation();
        return (
            <View style={{marginHorizontal: 30}}>
                    <Text style={styles.documentRef}>
                            {t("offer.pdf_document.texts.reference_short")} {t("offer.pdf_document.texts.estimate")} {estimateID}
                    </Text>
                    <Text style={styles.text}>
                            {t("offer.pdf_document.texts.project_id")} {project.id} &nbsp; &nbsp; &nbsp;
                            {t("offer.pdf_document.texts.project_name")} {project.name}
                    </Text>
                    <Text style={styles.txtSection}>
                            {t("offer.pdf_document.texts.entry")}
                    </Text>
                    <Text style={[styles.txtSection, {marginBottom:"15px"}]}>
                            {t("offer.pdf_document.texts.all_details")}
                    </Text>
            </View>
        );
}

export default Intro
