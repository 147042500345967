import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Quote() {
    const {t} = useTranslation()

    return (<>
        <RedHeader title={t('project.quotes_title')}/>
    </>)
}