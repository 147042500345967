import {useTranslation} from "react-i18next";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";
import React from "react";

function PersonContact({details, ...props}) {
    const { t } = useTranslation()

    return (<>
        <p>{t("person_contact_name")}: {details.first_name} {details.last_name}</p>
        <div className="row px-1">
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("telephone")}
                </p>
                <p style={TextStyle}>
                    {details.telephone}
                </p>
            </div>
            <div className="col-6">
                <p style={TitleStyle}>
                    {t("email")}
                </p>
                <p style={TextStyle}>
                    {details.email}
                </p>
            </div>
        </div>
    </>);
}

export default PersonContact;

