import {Text, View} from "@react-pdf/renderer";
import {styles, stylesStaffTable} from "./Styles/Styles";
import {useTranslation} from "react-i18next";
import {instrNameCol1, instrNameCol2, instrNameCol3, instrNameCol4} from "../../dictionary/users";

const Staff = ({eventStaff}) => {
    const { t } = useTranslation();

    const contextStaff = (el, i) => (
        <View key={i} style={stylesStaffTable.staffLine}>
            <Text style={stylesStaffTable.staffName}>
                {el}
            </Text>
            <Text style={stylesStaffTable.staffNumber}>
                {eventStaff[el].value || "-"}
            </Text>
        </View>
    )

    return (
    <View style={{paddingTop:15, marginHorizontal: 30, wrap: 'nowrap'}}>
        <Text style={[styles.redTitle,{padding:'5px',paddingLeft: 0}]}>{t("offer.pdf_document.staff.title")}</Text>
        <View style={stylesStaffTable.section}>

            {/* First Column Special style! */}
            <View style={[stylesStaffTable.column, {paddingLeft: 0}]}>
                {instrNameCol1.map((el, i) => contextStaff(el, i))}
            </View>

            {/* 2nd Column */}
            <View style={stylesStaffTable.column}>
                {instrNameCol2.map((el, i) => contextStaff(el, i))}
            </View>
            {/* 3rd Column */}
            <View style={stylesStaffTable.column}>
                {instrNameCol3.map((el, i) => contextStaff(el, i))}
            </View>
            {/* Last Column Special style */}
            <View style={stylesStaffTable.lastColumn}>
                {instrNameCol4.map((el, i) => contextStaff(el, i))}
            </View>
        </View>
    </View>
)
};

export default Staff
