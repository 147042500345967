import { Route, Routes } from "react-router-dom";
import PersonalData from "./PageContent/Profile/PersonalData";
import AdminSettings from "./PageContent/AdminSettings";
import Notifications from "./PageContent/Notifications";
import InstrumentsList from "./PageContent/Instruments/InstrumentsList";
import InstrumentEdit from "./PageContent/Instruments/InstrumentEdit";
import AsblSettings from "./PageContent/ASBL/Settings/AsblSettings";
import Home from "./PageContent/Home";
import SavedCalculations from "./PageContent/Sales/calculator/SavedCalculations";
import ClientsRoutes from "./PageContent/Routes/ClientsRoutes";
import LocationsRoutes from "./PageContent/Routes/LocationsRoutes";
import MusiciansRoutes from "./PageContent/Routes/MusiciansRoutes";
import Management from "./PageContent/ASBL/Management/Management";
import MemberAddEdit from "./PageContent/ASBL/Management/MemberAddEdit";
import {BusinessData} from "./PageContent/ASBL/BusinessData/BusinessData";
import MemberDetails from "./PageContent/ASBL/Management/MemberDetails";
import Formular from "./PageContent/ASBL/BusinessData/Formular";
import Offer from "./PageContent/Projects/Offer/Offer";
import Tests from "./PageContent/Projects/Offer/Tests";
import ProjectsRoutes from "./PageContent/Routes/ProjectsRouters";
import CalculatorLogic from "./PageContent/Sales/calculator/CalculatorLogic";

function RoutesAdmin() {
	// const [dataCalculation, setDataCalculation] = useState({});

	return (
		<>
			{/*<ProjectContext.Provider*/}
			{/*	value={{*/}
			{/*		calculationId: 0,*/}
			{/*		data: dataCalculation,*/}
			{/*		setData: setDataCalculation,*/}
			{/*	}}>*/}
				<Routes>
					<Route exact path={"/home"} element={<Home />} />
					<Route
						exact
						path={"/profile/personal-data"}
						element={<PersonalData />}
					/>
					<Route exact path={"/instruments"} element={<InstrumentsList />} />
					<Route
						exact
						path={"/instruments/edit/:key"}
						element={<InstrumentEdit />}
					/>
					<Route exact path={"/notifications"} element={<Notifications />} />
					<Route exact path={"/sales/calculator"} element={<CalculatorLogic />} />
					<Route
						exact
						path={"/sales/calculator/:key"}
						element={<CalculatorLogic />}
					/>
					<Route
						exact
						path={"/sales/saved_calculations"}
						element={<SavedCalculations />}
					/>
					<Route exact path={"/asbl/settings"} element={<AsblSettings />} />
					<Route exact path={"/asbl/association"} element={<Management />} />
					<Route
						exact
						path={"/asbl/business-data"}
						element={<BusinessData />}
					/>
					<Route
						exact
						path={"/asbl/business-data/edit"}
						element={<Formular />}
					/>
					<Route
						exact
						path={"/asbl/association/new/member"}
						element={<MemberAddEdit />}
					/>
					<Route
						exact
						path={"/asbl/association/details/member/:key"}
						element={<MemberDetails />}
					/>
					<Route
						exact
						path={"/asbl/association/edit/member/:key"}
						element={<MemberAddEdit />}
					/>
					<Route exact path={"/settings"} element={<AdminSettings />} />
					<Route exact path={"/projects/offer/:key"} element={<Offer />} />
					<Route exact path={"/tests"} element={<Tests />}/>
				</Routes>
				<MusiciansRoutes />
				<LocationsRoutes />
				<ClientsRoutes />
				<ProjectsRoutes />
			{/*</ProjectContext.Provider>*/}
		</>
	);
}

export default RoutesAdmin;
