import {instrNameCol1, instrNameCol2, instrNameCol3, instrNameCol4} from "./dictionary/users";

const format_date_convert = (date) => {
    const ds = date.split('.');
    return `${ds[2]}-${ds[1]}-${ds[0]}`
};

const format_datetime_convert = (val) => {
    const new_dt = new Date(val)
    return new_dt.toLocaleString()
}

const orderTypeStaff = {c0: 'Tutti', c1: 'Strings', c2: 'Harmony', c3: 'Woods', c4: 'Brass'}

const columnTypeStaff = {
    Strings: instrNameCol1,
    Woods: instrNameCol2,
    Brass: instrNameCol3,
    Harmony: [...instrNameCol2, ...instrNameCol3, ...instrNameCol4,]
}

const group_name = Object.values(orderTypeStaff)

const getTypeListStaff = (staff) => {
    const typeStaff = Object.values(staff)
        .filter(el => el.value > 0)
        .reduce(function (acc, obj) {
            if (acc[obj.type] === undefined) {
                acc[obj.type] = [obj.name]
            } else
                acc[obj.type].push(obj.name)
            return acc
        }, {})

    let optionsList = []
    let [c1, c2, c3, c4] = [[], [], [], []]

    if (Object.keys(typeStaff).length > 0) { // Tutti
        optionsList.push(orderTypeStaff.c0)
    }
    if (orderTypeStaff.c1 in typeStaff) { // Strings
        c1 = typeStaff[orderTypeStaff.c1]
    }
    if (orderTypeStaff.c2 in typeStaff) { // Harmony
        c2 = typeStaff[orderTypeStaff.c2]
    }
    if (orderTypeStaff.c3 in typeStaff) { // Woods
        c3 = typeStaff[orderTypeStaff.c3]
    }
    if (orderTypeStaff.c4 in typeStaff) { // Brass
        c4 = typeStaff[orderTypeStaff.c4]
    }
    ////
    const harmony = [].concat(c3, c4, c2)
    if (c1.length > 1) {
        optionsList.push(orderTypeStaff.c1)
    }
    if (harmony.length > 1) {
        optionsList.push(orderTypeStaff.c2)
    }
    if (c3.length > 1) {
        optionsList.push(orderTypeStaff.c3)
    }
    if (c4.length > 1) {
        optionsList.push(orderTypeStaff.c4)
    }

    // lista wybranych instrumentow
    const newOrderStrings = []
    columnTypeStaff.Strings.forEach((elem) => {
        if (c1.includes(elem)) {
            newOrderStrings.push(elem)
        }
    })

    const newOrderHarmony = []
    columnTypeStaff.Harmony.forEach((elem) => {
        if (harmony.includes(elem)) {
            newOrderHarmony.push(elem)
        }
    })

    optionsList.push(...newOrderStrings, ...newOrderHarmony)

    const selectedStaff = optionsList.map(el => {
       const colorTest = group_name.some(name => el === name )
        return {
            value: el,
            label: el,
            color: colorTest ? '#00ace6' : 'black',
        };
    });

    return selectedStaff
}

const getNameColumnTypeStaff = (instr) => {
    const typeNameStaffObj = Object.entries(columnTypeStaff)
    const foundObj = typeNameStaffObj.find(entry => {
        const [k, v] = entry;
        const typeName = v.includes(instr)
        if (typeName) {
            return typeName
        }
    })
    if (foundObj) {
        return foundObj[0]
    } else return ""
}

const changeCompOptions = (compOpt, groupInstr) => {
    // znajdz grupe instr w tablicy obiektow
    const compOptCopy = structuredClone(compOpt)

    const newCompOpt = []
    compOptCopy.forEach(el => {
        if (!columnTypeStaff[groupInstr].includes(el.value)) {
            newCompOpt.push(el)
        }
    })
    return newCompOpt
}

const musiciansCount = (instr) => {
    let musiciansSum = 0
    Object.values(instr).forEach(el => (musiciansSum += +el.value))
    return musiciansSum
}

const calculation_sections_sum = (data) => {
    return data.reduce((acc, obj) => {
        acc.client_cost += +obj.client_cost
        acc.estro_cost += +obj.estro_cost
        return acc
    }, {client_cost: 0, estro_cost: 0})
}

const typeBlock = ['administration', 'archiving', 'transport', 'renting']

const summary_sections = (doc) => {
    let summary_block = {}
    typeBlock.forEach(block => {
        if (doc[block].length > 0 && doc[block].client_cost && doc[block].estro_cost) {
            const sum_block = calculation_sections_sum(doc[block])
            summary_block[block] = sum_block
        }
    })
    return summary_block
}

async function imageUrlToFile(imageUrl) {
    try {
        const response = await fetch(imageUrl);

        if (!response.ok) {
            throw new Error(`Failed to fetch image (status ${response.status})`);
        }
        const blob = await response.blob();
        const filename = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
        const file = new File([blob], filename, { type: blob.type });
        return file;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0
}

export {
    format_date_convert,
    format_datetime_convert,
    getNameColumnTypeStaff,
    orderTypeStaff,
    columnTypeStaff,
    getTypeListStaff,
    changeCompOptions,
    musiciansCount,
    imageUrlToFile,
    calculation_sections_sum,
    isObjectEmpty,
    summary_sections,
}