import {Input, InputWrapperDiv} from "../../../../../components/ui/Input";
import React from "react";
import {useTranslation} from "react-i18next";
import FormAddress from "./FormAddress";
import Select from "react-select";
import select_styles from "../../../../../layouts/elements";


const FormFirm = ({form, typesFirm, changeHandler, required=false}) => {
    const { t } = useTranslation()

    return (<>
        <div className="row pb-2">
            <InputWrapperDiv>
                {t("company.name")+' *'}
                <Input
                    name="firm_name"
                    value={form.name}
                    onChange={val => changeHandler(val, 'name')}
                    required={required}
                />
            </InputWrapperDiv>
        </div>

        <div className="row pb-2">
            <InputWrapperDiv>
                <div className="customSelectParent" style={{position: 'relative'}}>
                    {t("company.type")+' *'}
                    <Select
                        name='firmsTypeSelect'
                        options={typesFirm}
                        value={typesFirm.filter( obj => form.type.value === obj.value )}
                        onChange={val => changeHandler(val, 'type')}
                        styles={select_styles}
                        unstyled
                        required={required}
                    />
                </div>
            </InputWrapperDiv>
        </div>
        
        <div className='container py-3 px-0'>
            <FormAddress form={form} changeHandler={changeHandler} required={true}/>
        </div>

        <div className="row pb-2">
            <InputWrapperDiv>
                <div>
                    {t("company.note")}
                    <textarea
                        name="note"
                        value={form.note || ""}
                        onChange={val => changeHandler(val.target.value, 'note')}
                        style={{width: '100%'}}
                    />
                </div>
            </InputWrapperDiv>
        </div>
    </>)
}

export default FormFirm;

