import DarkCard from "../../../../../../components/card/DarkCard";
import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import LightCard from "../../../../../../components/card/LightCard";
import Budget from "./Budget";
import Error404 from "../../../../../Error404";
import LoadingData from "../../../../../LoadingData";
import Estimate from "./Estimate";
import {project} from "../../../../../../services/api/endpoints";
import {useEndpoint} from "../../../../../../hooks/useEndpiont";
import Status from "./Status";
import Quote from "./Quote";
import Staff from "./Staff"
import Events from "./Events";
import FeedsList from "./FeedsList";
import {useTranslation} from "react-i18next";
import {RedirectButton} from "../../../../../../helpers/Redirect";
import ClientOrFirmCard from "./ClientOrFirmCard";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {useSessionStorage} from "usehooks-ts";
import {projectSession} from "../../../../../../services/sessionData";
import styled from "styled-components";
import { Colors } from "../../../../../../components/theme";
import { Link } from "react-router-dom";
import WhiteCard from "../../../../../../components/card/WhiteCard";
import {Button} from "../../../../../../components/ui/Button";

const TxtTitles = styled.p`
	font-size: 0.7em;
    text-transform: uppercase;
	width: 100%;
    padding: 0 0 3px 12px;
    margin: 0;
    color: ${Colors.darkRed};
`;

export default function ProjectCard() {
    const params = useParams()
    const [{data, loading, error}] = useEndpoint(project.details(params.id))
    const projectData = data
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [sessionData, setSessionData] = useSessionStorage("CALCULATION", projectSession);
    const [estimate_current, setEstimateCurrent] = useState(0)
    const [notes, setNotes] = React.useState("")


    useEffect(() => {
        if (projectData) {
            setEstimateCurrent(projectData.estimates.find(el => el.current).id)
        }
    }, [projectData])

    const redirect_to = (what, calculation_id, estimation_id, currentEst) => {
        const estimate_selected = estimation_id ? estimation_id : estimate_current;
        if (what === 'project') {
            // console.log("project => appData:")
            // const estimate_id = estimate_selected ? estimate_selected.id : 0
            setSessionData({
                ...sessionData,
                name: "project",
                pathReturn: "/projects/in-preparation/"+params.id,
                projectID: params.id,
                projectName: projectData.name,
                calculationID: projectData.calculation_pk,
                estimateID: estimate_selected,
                estimateCurrent: currentEst,
            })
            navigate("/sales/calculator/"+calculation_id)
        } else {    // Create Estimate
            setSessionData({
                ...sessionData,
                name: "estimate",
                pathReturn: "/projects/in-preparation/"+params.id,
                projectID: params.id,
                projectName: projectData.name,
                calculationID: 0,
                estimateID: estimate_selected,
                estimateCurrent: false,
            })
            navigate("/sales/calculator")
        }
    }

    if (loading) return <LoadingData/>
    if (error) return <Error404/>
    if (projectData.status === 404) return (
        <DarkCard classNames='mb-3 row'>
            Projects > Project ID: P{params.id}
            <br /><br />
            No project data!
        </DarkCard>
    )

    const clientAndFirm = {
        client: projectData.clientData,
        firm: projectData.firmData,
    }

    const changeHandler = (n) => {
        setNotes(n)
    }

    const handleClickUpdate = () => {

    }

    return (<>
        <DarkCard classNames='mb-3 row'>
            <p className="mt-0 mb-0 breadcrumbs">
                {t("menu.projects",{ ns: 'menu' })} &#9656; 
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link> &#9656; 
                {t("breadcrumbs.project_title")} P{params.id}
            </p>
        </DarkCard>
        <DarkCard classNames='mb-3 row'>
            <TxtTitles>{t("project.project_name_title")}</TxtTitles>
            <h1 className='mb-0' style={{marginTop:'-5px'}}>
                {projectData.name}
            </h1>
            <p className="mt-3" style={{fontSize:'14px'}}>
                {t("project.project_id_title")} P{params.id} <br/>
                {t("project.selected_estimate_title")} E{estimate_current}
            </p>

            <LightCard classNames={'mb-3'}>
                <Status />
            </LightCard>

            {projectData.estimates.length > 0
                ? (<>
                    <div className='mb-3'>
                        <RedirectButton redirect_to_path={() => redirect_to("project", projectData.calculation_pk, true)}>
                            {t("common.button.edit_estimate")}
                        </RedirectButton>
                        
                        <div className="row">
                            <div className="col-6">
                                <LightCard>
                                    <RedHeader title={t('project.budget_title')+' (E'+estimate_current+')'}/>
                                    <Budget projectData={projectData} estimationID={estimate_current}/>
                                </LightCard>
                            </div>
                            <div className="col-6">
                                <LightCard>
                                    <RedHeader title={t('project.client_title')}/>
                                    <ClientOrFirmCard details={clientAndFirm} />
                                </LightCard>
                            </div>
                        </div>
                        
                    </div>

                    <div className='mb-3'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to("estimate")}>
                                {t("common.button.create_estimate")}
                            </RedirectButton>
                        </div>

                        <LightCard>
                            <Estimate
                                estimateData={projectData.estimates}
                                clientAndFirm={clientAndFirm}
                                grantEstroSum={projectData.totaling.grantEstroSum}
                                project={{name: projectData.name, id: projectData.id}}
                                redirect_to={redirect_to}
                            />
                        </LightCard>
                    </div>

                    <div className='mb-3'>
                        <LightCard>
                            <Quote />
                        </LightCard>
                    </div>

                    <div className='mb-3'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to("project", projectData.calculation_pk, true)}>
                                {t("common.button.edit_estimate")}
                            </RedirectButton>

                        </div>

                        <LightCard>
                            <Staff staff={projectData.staff} kzmProject={projectData.kzmProject} estimationID={estimate_current}/>
                        </LightCard>
                    </div>

                    <div className='mb-3'>
                        <div className="mt-5 mb-2" style={{overflow:'hidden'}}>
                            <RedirectButton redirect_to_path={() => redirect_to("project", projectData.calculation_pk, true)}>
                                {t("common.button.edit_estimate")}
                            </RedirectButton>
                        </div>
                        <LightCard>
                            <Events eventDetail={projectData.eventDetail} estimationID={estimate_current} />
                        </LightCard>
                    </div>
                  </>)
                : <div>{t("no data")}</div>
            }
        </DarkCard>
        <DarkCard classNames='mb-3 row'>
            <RedHeader title={t('project.feed_title')}/>
            <div className='mb-3'>
                <LightCard>
                    <div className='row'>
                            <div>
                                {t("profile.note")}
                                <textarea
                                    name="note"
                                    value={notes}
                                    onChange={val => changeHandler(val.target.value)}
                                    style={{width: '100%'}}
                                />
                            </div>
                        </div>

                    <div className='row'>
                        Atachement add: <input type="file" id="files" name="files" multiple/>

                        <Button onClick={handleClickUpdate} style={{float:'left'}}>
                            {t("clients.upload_files")}
                        </Button>
                    </div>
                </LightCard>
            </div>
            <div className='mb-3'>
                <FeedsList feedData={projectData.feeds} />
            </div>
        </DarkCard>
    </>)


}