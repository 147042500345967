import React from "react";
import {useTranslation} from "react-i18next";
import WhiteCard from "../../../../../../components/card/WhiteCard";
import FeedDetails from "./FeedDetails";


export default function FeedsList({feedData}) {
    const {t} = useTranslation()

    return (<>
        {feedData.map((box, index) =>
            <div id={"day_card_"+index} className='mt-3'>
                <div>
                    <p className="mb-2"><b>{Object.keys(box)[0]}</b></p>
                </div>

                {feedData.map((box, index) =>
                    <WhiteCard id={"white_card_" + index}
                               classNames='mt-3 d-flex flex-row flex-wrap flex-md-nowrap align-items-center p-3'>
                        <div className='row'>
                            <dev>{Object.keys(box)[0]}<br/></dev>
                            <FeedDetails data={Object.values(box)[0]}/>
                            <br/>
                        </div>
                    </WhiteCard>
                )}
            </div>
        )}
    </>)
}