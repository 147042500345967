import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DarkCard from "../../../../../components/card/DarkCard";
import {firmDetailAPI} from "../../../../../services/api/clients";
import LightCard from "../../../../../components/card/LightCard";
import RedHeader from "../../../../../components/ui/RedBgTitleHeader";
import {InputWrapperDiv} from "../../../../../components/ui/Input";
import { EditButton } from "../../../../../components/ui/EditButton";
import Addresses from "../../../../../components/ui/Addresses";
import { Colors } from "../../../../../components/theme";


const FirmsDetails = () => {
    const params = useParams()
    const { t } = useTranslation()
    const [firm, setFirm] = useState({})

    useEffect(() => {
        const getDetail = async () => {
            try {
                const getDetail = await firmDetailAPI(params.key)
                setFirm(getDetail.data)
            } catch (e) {
                console.info("Failed to download details firm...", e)
                return {}
            }
        }
        getDetail()
    }, [])

    return (<>
        <DarkCard classNames='mb-3 row'>
            Breadcrumbs: Company details
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
            <h1>
                <span className="me-4">{firm.name}</span>
                <EditButton gotoName={"/clients/companies/edit/"+firm.id}/>
            </h1>
            <div className="row">
                <div className="col-lg-6 col-xl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("company.details")}/>
                        <InputWrapperDiv addClass="px-2">
                            <p className="mb-0" style={{fontWeight:'bold'}}>
                                {firm.name}
                            </p>
                            <p className="mt-0">{firm.type ? firm.type.label : null}</p>
                            <Addresses obj={firm} />
                            {
                                firm.note ?
                                    <p className="mt-1">
                                        <b style={{color:`${Colors.darkRed}`}}>{t("profile.note")}:</b> <br/>
                                        {firm.note}
                                    </p>
                                    : null
                            }
                        </InputWrapperDiv>
                    </LightCard>
                </div>
                <div className="col-lg-6 col-xl-4 mb-4">
                    <LightCard>
                        <RedHeader title={t("company.contact_person")}/>
                        {firm.clients && firm.clients.length>0 ? firm.clients.map((client, index) => {
                            return (
                                <div className="px-2" key={index} style={{borderBottom:'1px solid #000'}}>
                                    <p><b>{client.first_name} {client.last_name}</b></p>
                                    <p>
                                        Tel.: <a href={"tel:" + client.telephone}>{client.telephone}</a> <br/>
                                        Mail: <a href={"mailto:"+ client.email}>{client.email}</a>
                                     </p>
                                    {client.responsible_for.length>0
                                        ? <p className="pb-0">
                                            <b style={{color:`${Colors.darkRed}`}}>{t("profile.responsibility")}:</b><br/>
                                            {client.responsible_for}
                                          </p>
                                        : null
                                    }
                                </div>
                            )})
                        :
                            <InputWrapperDiv addClass="px-2">
                                {t("company.no_contacts")}
                            </InputWrapperDiv>
                        }
                    </LightCard>
                </div>
            </div>
        </ DarkCard>
    </>)
}

export default FirmsDetails

