import {Colors} from "../../theme";
import styled from "styled-components";

const handleVariantOld = variant => {
    switch (variant) {
        case "error":
            return `
                background-color: ${Colors.infoBG};
                color: ${Colors.info};
                border: 1px solid ${Colors.info};
            `;
        case "success":
            return `
                background-color: ${Colors.errorBG};
                color: ${Colors.error};
                border: 1px solid ${Colors.error};
            `;
        case "editF":
            return `
                background-color: ${Colors.editBG};
                color: ${Colors.edit};
                border: 1px solid ${Colors.edit};
            `;
        default:
            return `
                background-color: ${Colors.infoBG};
                color: ${Colors.info};
                border: 1px solid ${Colors.info};
            `;
    }
}

const handleVariantColors = variant => {
    switch (variant) {
        case "error":
            return Colors.error; // red
        case "success":
            return Colors.info; // green
        case "editF":
            return Colors.edit; // orange
        default:
            return Colors.black; // black
    }
}

const PopupStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.7);
    z-index: 10;
`

const PopupMessageContent = styled.div`
    position: absolute;
    max-width: 400px;
    width: 100%;
    min-height: 150px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 45px 25px 25px 25px;
    text-align: center;
    background-color: ${Colors.white};
`

const PopupMessageIcon = styled.div`
    border-radius: 50%;
    border: 5px solid ${Colors.white};
    height: 70px;
    width: 70px;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${Colors.white};
`

const PopupMessageTitle = styled.div`
    font-size: 1.7rem;
    font-weight: bold;
    color: ${({variant}) =>
    variant === 'error' && `${Colors.red}` ||
    variant === 'success' && `${Colors.green}` ||
    variant === 'editF' && `${Colors.orange}` ||
    `${Colors.black}`
};
`

const PopupMessageDescription = styled.div`
    font-size: 1.1rem;
    margin: 10px 0px;
`

export {
    PopupStyle,
    PopupMessageIcon,
    PopupMessageTitle,
    PopupMessageDescription,
    PopupMessageContent,
    handleVariantColors,
}