import DarkCard from "../../../../../components/card/DarkCard";
import { useTranslation } from "react-i18next";
import LordIcon from "../../../../../components/ui/LordIcon";
import { Link } from "react-router-dom";
import { Colors } from "../../../../../components/theme";
import LocationListElement from "./LocationListElement";
import {useEffect, useState} from "react";
import {locationsListAPI} from "../../../../../services/api/locations";
import {getCountriesListAPI} from "../../../../../services/api/user";

function LocationsList () {
    const { t } = useTranslation();
    const [locations, setLocations] = useState([])
    const [countries, setCountries] = useState([])

    function compareByName(a, b) {
        return a.name.localeCompare(b.name);
    }

    useEffect(() => {
        const getLocationsList = async () => {
            try {
                const response = await locationsListAPI()
                const locationsResp = response.data
                setLocations(locationsResp.sort(compareByName))
            } catch (e) {
                console.log("Failed to download locations data")
            }
            try {
                const resp_list = await getCountriesListAPI()
                const data = resp_list.data.qs.map(el => {
                    return {
                        value: el.name,
                        label: el.name,
                        pk: el.pk,
                    }
                })
                setCountries(data)
            } catch (ex) {
                console.log("Failed to download locations list.", ex);
            }
        }
        getLocationsList()
    }, [])

    return (
        <>
            <DarkCard classNames='mb-3 row'>
                Breadcrumbs: Locations list
            </DarkCard>

            <DarkCard classNames='mb-3 row'>
                <div className="row">
                    <div className="col-sm-8">
                        <Link className="addGroup" to={'new'}>
                            <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                            <span className="ps-2">{t("common.button.add_location")}</span>
                        </Link>
                    </div>
                    <div className="col-sm-4 mt-3 mt-sm-0">
                        <input style={{width:'100%'}} placeholder="Search" disabled></input>
                    </div>
                </div>
                <div className="mt-4">
                    {
                        locations.map(loc => {
                            return <LocationListElement
                                key={loc.id}
                                lDetailsUrl={`location/${loc.id}`}
                                lName={loc.name}
                                lCity={loc.city}
                                lCountry={loc.country}
                                lMap={loc.map_url}
                                lNote={loc.note}
                                countries={countries}
                            />
                        })
                    }
                </div>
            </DarkCard>

        </>
    )
};


export default LocationsList;