import React from "react";
import {useTranslation} from "react-i18next";
import {TextStyle, TitleStyle} from "../../../../../../layouts/styles";
import Address from "../Offer/Address";


const FirmDetails = (props) => {
    const { t } = useTranslation()
    const details = props.details

    console.log("FirmDetails", details)

    return (<>
        <div className="row px-1">
            <div className="col-7">
                <p style={TitleStyle}>
                    {t("firmName")}<span style={TextStyle}>: {details.name}</span>
                </p>
                <p style={TitleStyle}>
                    {t("firmType")}<span style={TextStyle}>: {details.type.name}</span>
                </p>
            </div>
        </div>
        <Address details={details}/>
        <div className="row px-1">
            <div className="col-7">
                {props.children}
            </div>
        </div>
    </>)

}

export default FirmDetails

