import DarkCard from "../../../../../../components/card/DarkCard";
import React, {useEffect, useState} from "react";
import {ProjectItem} from "./ProjectItem";
import {useTranslation} from "react-i18next";
// import LoadingData from "../../../../../LoadingData";
import Error404 from "../../../../../Error404";
import {projectListAPI} from "../../../../../../services/api/project";
import { Link } from "react-router-dom";


export default function ProjectsList() {
    const { t } = useTranslation()
    const [projectsList, setProjectsList] = useState([])

    // if (loading) return <LoadingData />

    useEffect(() => {
        const initData = async () => {
            try {
                const response = await projectListAPI()
                if (response.status === 200) {
                    setProjectsList(response.data)
                } else return <Error404 />
            } catch (e) {
                console.error(e);
            }
        }
        initData()
    }, [])

    console.log("Project List:", projectsList)

    return (<>
        <DarkCard classNames='mb-3 row'>
            <p className="mt-0 mb-0 breadcrumbs">
                {t("menu.projects",{ ns: 'menu' })} &#9656; 
                <Link to={'/projects/in-preparation'}>{t("menu.inprep_projects",{ ns: 'menu' })}</Link>
            </p>
        </DarkCard>

        <DarkCard classNames='mb-3 row'>
            <h1 className='mt-0'>{t("project.projects_in_prep_title")}</h1>

            {projectsList.length > 0
                ? projectsList.map(project => <ProjectItem key={project.id} project={project}/>)
                : <p>{t("project.no_data")}</p>
            }
        </DarkCard>
    </>)
}


