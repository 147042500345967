import {
    PopupMessageContent,
    PopupMessageIcon,
    PopupMessageTitle,
    PopupStyle,
    handleVariantColors,
    PopupMessageDescription
} from "./PopupStyles";
import LordIcon from "../LordIcon";


const Header = ({variant, title}) => {
    return (<>
        <PopupMessageIcon variant={variant}>
            <LordIcon url={variant} color={handleVariantColors(variant)} target='.popup' size='60px'/>
        </PopupMessageIcon>

        <PopupMessageTitle variant={variant}>
            {title}
        </PopupMessageTitle>
    </>)
}

const Popup = ({variant, title, message, children}) => {
    return (
        <PopupStyle>
            <PopupMessageContent className="popup">
                <Header variant={variant} title={title}/>
                <PopupMessageDescription>
                    {message ? message : null}
                    {children}
                </PopupMessageDescription>
            </PopupMessageContent>
        </PopupStyle>
    )
}

export default Popup
