import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {usePDF} from "@react-pdf/renderer";
import {PDFFile} from "../../../../../../helpers/PDFSupport/offers/PDFFile";
import { saveAs } from 'file-saver';
import PopupForm from "../../../../../../components/ui/PopupForm";
import {useNavigate} from "react-router-dom";
// import PropTypes from "prop-types";

function pdfData(data) {
    return {
        estimateID: data.estimateID,
        calculation: data.calculation,
        showEstroGrant: data.showEstroGrant,
        client: data.client,
        estimateDate: data.estimateDate,
        firm: data.firm,
        project: data.project,
        program: data.program,
        comment: data.comment,
    }
}


const GeneratorPDF = ({dataToRender, offer_processName, dispatch, popupError, popupInitial}) => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [popupPDF, setPopupPDF] = useState(popupInitial)
    const popupLoadingPDF = {
        show: true,
        variant: t('loading'),
        title: t('Loading...'),
        message: t("PDF is being generated. Please wait...")
    }
    const popupGeneratePDF = {
        show: true,
        variant: t('success'),
        leftBtn: t('common.button.cancel'),
        rightBtn: t('common.button.download'),
        title: t('popups.generate_estimate_pdf.title'),
        message: t("popups.generate_estimate_pdf.success")
    }

    const document = <PDFFile
        doc={dataToRender}
    />
    const [instance, updateInstance] = usePDF({document})

    useEffect(() => {
        if (instance.loading) {
            setPopupPDF(popupLoadingPDF)
        } else if (instance.error) {
            setPopupPDF(popupError)
        } else {
            setPopupPDF(popupGeneratePDF)
        }
    }, [instance]);

    const handlePopup = async popupDict => {
        if (popupDict.type === 'save') {
            await saveAs(instance.url, `${offer_processName}_${dataToRender.estimateID}.pdf`);
            // window.location.assign(instance.url)
            navigate("/dashboard")
        }
        if (popupDict.type === 'ok') {
            dispatch({type: "SET_POPUP", popup: popupError})
        }
        if (popupDict.type === 'cancel') {
            setPopupPDF(popupInitial)
            dispatch({type: "SET_GENERATE_PDF", isGeneratePDF: false, estimateID: undefined })
        }
    };

    console.info("GeneratorPDF popup show:", popupPDF)

    return popupPDF.show ? <PopupForm
        handlePopup={handlePopup}
        variant={popupPDF.variant}
        title={t(popupPDF.title)}
        message={t(popupPDF.message)}
        leftBtn={popupPDF.leftBtn}
        rightBtn={popupPDF.rightBtn}
        okBtn={popupPDF.okBtn}
        context={popupPDF.context}
    /> : null;

}


export {
    pdfData,
    GeneratorPDF,
}
