import React from "react";
import {useTranslation} from "react-i18next";
import RedHeader from "../../../../../../components/ui/RedBgTitleHeader";
import {InputWrapperDiv} from "../../../../../../components/ui/Input";
import Select from "react-select";
import select_styles from "../../../../../../layouts/elements";
import ClientDetails from "./ClientDetails";
import { Link } from "react-router-dom";
import LordIcon from "../../../../../../components/ui/LordIcon";
import { Colors } from "../../../../../../components/theme";


const ClientSelect = (props) => {
    const {
        clientsList,
        client,
        handleNewEdit,
        dispatch
    } = props
    const { t } = useTranslation()

    console.log("CLIENT: ", client)

    return (
        <div className="col-lg-6 col-xl-4 mb-4">
            <RedHeader title={t("offer.create_offer.client_data_title")}/>
            <div className="row pb-2">
                <InputWrapperDiv>
                    <div className='customSelectParent'>
                        {t("offer.create_offer.select_client_txt")}
                        <Link className="addGroup pull-right" to={"/clients/contacts/create"}>
                            <LordIcon url='cross' color={`${Colors.red};`} rotate='45' target='.addGroup'/>
                            <span className="ps-2" onClick={handleNewEdit}>{t("common.button.add_client")}</span>
                        </Link>

                        <Select
                            name='client-list'
                            options={clientsList}
                            value={client}
                            onChange={client => dispatch({type: "SELECT_CLIENT", client})}
                            styles={select_styles}
                            unstyled
                            required="required"
                            className="mt-3"
                        />
                    </div>
                </InputWrapperDiv>
            </div>

            {client.client_data
                ? <ClientDetails
                    details={client.client_data}
                    client={client}
                    handleEditClient={handleNewEdit}
                    dispatch={dispatch}
                />
                : null
            }
        </div>
    )
}

export default ClientSelect
