import {
    asblBusinessDetailsAPI,
    asblManagementDataAPI,
    getPrintingOnDocPersonsAPI,
    getPrintingOnDocumentAPI
} from "./api/user";

const getMamagementData = async () => {
    try {
        const clientsData = await asblManagementDataAPI()
        return clientsData.data
    } catch (e) {
        console.log("Failed to download management data", e);
        return
    }
}

const getBusinessData = async () => {
    try {
        const resp = await asblBusinessDetailsAPI()
        return  resp.data
    } catch (e) {
        throw new TypeError("Failed to download details data.")
        return
    }
}

const getPrintingDocSelected = async () => {
    try {
        const resp = await getPrintingOnDocumentAPI()
        return  resp.data
    } catch (e) {
        throw new TypeError("Failed to download details printing document data.")
        return
    }
}

const getPrintingDocPersonsList = async () => {
    try {
        const resp = await getPrintingOnDocPersonsAPI()
        console.log("response AAAAAA>>>>", resp.data)
        return  resp.data
    } catch (e) {
        throw new TypeError("Failed to download details printing document data.")
        return
    }
}


export {
    getMamagementData,
    getBusinessData,
    getPrintingDocSelected,
    getPrintingDocPersonsList,
}